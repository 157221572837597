import React from 'react';
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import {callAdminAPI} from "../../../Commen/CommenFunction";
import Sidebar from "../Layout/Sidebar";


class UserDetail extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            posts:[]
        }
    }

    async componentDidMount() {
        this.setState({
            loading:true
        });
        const form = new FormData()
        form.set('user_id', this.props.match.params.id)
        const response = await callAdminAPI('admin/postList', 'post',form,'application/json');
        if (response.data.status === true) {
            if (response.data.status_code == 0){
                this.setState({
                    posts:response.data.data.data
                });
            }
        }
        this.setState({
            loading:false
        });
    }

    render() {
        return(
            <div id="wrapper">
                <Sidebar/>
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Header loading={this.state.loading} />
                        <div className="content-wrapper">
                            <section className="content-header">
                                <div className="container-fluid">
                                    <div className="row mb-2">
                                        <div className="col-sm-6">
                                            <h1>User's Videos</h1>
                                        </div>
                                        <div className="col-sm-6">
                                            <ol className="breadcrumb float-sm-right">
                                                <li className="breadcrumb-item"><a href={'/kurafatadmin'}>Home</a></li>
                                                <li className="breadcrumb-item"><a href={'/kurafatadmin/tournaments'}>Tournaments</a></li>
                                                <li className="breadcrumb-item active">User's Videos</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section className="content">
                                <div className="container-fluid">
                                    <div className="row">
                                        {
                                            this.state.posts.map((post, key) => {
                                                return (
                                                    <div className="col-3">
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <video controls poster={post.thumb_url} height={400} width={300}>
                                                                    <source src={post.file_url} type="video/mp4" />
                                                                </video>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        );
    }
}

export default UserDetail;