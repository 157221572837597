import React from 'react';
import Swiper from 'react-id-swiper';
// SCSS
import "swiper/swiper.scss";
// Assets
import cat1 from "../../../Assets/img/education.svg";
import cat2 from "../../../Assets/img/fashion.svg";
import cat3 from "../../../Assets/img/food.svg";
import cat4 from "../../../Assets/img/kids.svg";
import cat5 from "../../../Assets/img/lifestyle.svg";
import cat6 from "../../../Assets/img/technology.svg";
import cat7 from "../../../Assets/img/travel.svg";

class Categoryslider extends React.Component {
  render() {
    const params = {
      grabCursor: true,
      slidesPerView: 1,
      spaceBetween: 10,
      loop: true,
      breakpoints: {
        1200: {
          slidesPerView: 6,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 40,
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
        640: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        320: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
      },
    };
    return(
        <>
          <Swiper {...params}>
            <div><img src={ cat1 } alt="img"></img></div>
            <div><img src={ cat2 } alt="img"></img></div>
            <div><img src={ cat3 } alt="img"></img></div>
            <div><img src={ cat4 } alt="img"></img></div>
            <div><img src={ cat5 } alt="img"></img></div>
            <div><img src={ cat6 } alt="img"></img></div>
            <div><img src={ cat7 } alt="img"></img></div>
          </Swiper>
        </>
    )
  }
}


export default Categoryslider;