import React from 'react';
import WebUserProfile from "./WebUserProfile";
import MobileUserProfile from "../mobile/MobileUserProfile";
import Loading from "../Layout/Loading";
import MetaTags from "react-meta-tags";
import dummyImg from "../../Assets/img/dummy_user.png";
import videoIcon from "../../Assets/img/video-icon.svg";
import vdPic1 from "../../Assets/img/vd-pic1.png";
import NotFound from "../Layout/NotFound";
import {callAPI} from "../../Commen/CommenFunction";

class Home extends React.Component{

    constructor(props) {
        super(props);
        this.state={
            loading:true,
            user:[],
            post:[],
            found:false
        };
    }

    async componentDidMount() {
        if(this.props.history.location.state && this.props.history.location.state.user){
            this.setState({
                user:this.props.history.location.state.user,
                loading:false,
                found:true
            });
        } else {
            const formData = new FormData()
            formData.set('username', this.props.match.params.username)
            try {
                let response = await callAPI('userProfile', 'post', formData, 'application/json');
                if (response.status){
                    this.setState({
                        user:response.data,
                        post:response.data.get_top_post,
                        loading:false,
                        found:true
                    });
                } else {
                    this.setState({
                        loading:false,
                        found:false
                    });
                }
            }
            catch (e) {
                console.log(e);
            }
        }
    }

    render() {
        if(this.state.loading) {
            return (
                <Loading />
            );
        } else if(this.state.found) {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
                // true for mobile device
                return(
                    <MobileUserProfile user={this.state.user}/>
                )
            }else{
                // false for not mobile device
                return(
                    <WebUserProfile user={this.state.user} />
                );
            }
        } else {
            return (
                <NotFound />
            )
        }

    }
}

export default Home;