import React from 'react';
import "./VideoFooter.css"
import {Link} from "react-router-dom";
import ReactHashtag from "react-hashtag";
import $ from "jquery";
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import Ticker from 'react-ticker'


function VideoFooter({channel, description, song}) {
    $('.description-hashtag').find('span').addClass('hashtag_clickable')

    let history = useHistory();


    $('.hashtag_clickable').off().on('click', function (){
        history.push({
            pathname: '/tagVideo',
            state: { tag:$(this).text() }
        });
    });

    return (
        <div className="videoFooter">
            <div className="videoFooter_text">
                <h3><Link  to={{
                    pathname: 'u/'+channel.username,
                    state: { user:channel }
                }}>@{channel.name}</Link></h3>
                <p className="description-hashtag">
                    <ReactHashtag>
                        {description}
                    </ReactHashtag>
                </p>
                <div className="videoFooter_ticker">
                    <MusicNoteIcon className="videoFooter_icon"/>
                    <Ticker mode="smooth">
                        {({ index }) => (
                            <>
                                <p>{song.title ? song.title : ''}</p>
                            </>
                        )}
                    </Ticker>
                </div>
            </div>
            <Link  to={{
                pathname: 'musicVideo',
                state: { music:song }
            }}><img className="videoFooter_record" src={song.image_url ? song.image_url : "https://static.thenounproject.com/png/934821-200.png"} alt=""/></Link>
        </div>
    )
}

export default withRouter(VideoFooter);