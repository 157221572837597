import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Home from "../Components/Home/Home";
import VideoPlayer from "../Components/video/VideoPlayer";
import userProfile from "../Components/Home/userProfile";
import MusicLibrary from "../Components/mobile/MusicLibrary";
import uploadVideo from "../Components/mobile/uploadVideo";
import PlayVideoSection from "../Components/mobile/PlayVideoSection";
import Login from "../Components/mobile/Login";
import Register from "../Components/mobile/Register";
import PrivacyPolicy from "../Components/About/PrivacyPolicy";
import TermCondition from "../Components/About/TermCondition";
import Faq from "../Components/About/Faq";
import AdminLogin from "../Components/Admin/Login";
import Dashboard from "../Components/Admin/Dashboard";
import Users from "../Components/Admin/Pages/Users";
import {userData} from "./CommenFunction";
import Tournaments from "../Components/Admin/Pages/Tournaments";
import TournamentDetail from "../Components/Admin/Pages/TournamentDetail";
import CreateEvent from "../Components/Admin/Pages/CreateEvent";
import Music from "../Components/Admin/Pages/Music";
import Visitor from "../Components/Admin/Pages/Visitor";
import CreateMusic from "../Components/Admin/Pages/CreateMusic";
import Videos from "../Components/Admin/Pages/Videos";
import BlockedVideos from "../Components/Admin/Pages/BlockedVideos";
import UserDetail from "../Components/Admin/Pages/UserDetail";
import DataDeletion from "../Components/About/DataDeletion";
import PromotionalImages from "../Components/Admin/Pages/PromotionalImages";
import Search from "../Components/mobile/Search";
import TagLibrary from "../Components/mobile/TagLibrary";
import MyProfile from "../Components/mobile/MyProfile";
import Winners from "../Components/Admin/Pages/Winners";
import Withdraw from "../Components/Admin/Pages/Withdraw";

function Routes() {
    let routes = (
        <Switch>
            <Route path="/" component={Home} exact/>
            <Route path="/player" component={VideoPlayer} exact/>
            <Route path="/kurafatadmin" component={AdminLogin} exact/>
            <Route path="/privacy-policy" component={PrivacyPolicy} exact/>
            <Route path="/terms" component={TermCondition} exact/>
            <Route path="/faq" component={Faq} exact/>
            <Route path="/help-center" component={PrivacyPolicy} exact/>
            <Route path="/about" component={PrivacyPolicy} exact/>
            <Route path="/datadeletion" component={DataDeletion} exact/>
            <Route path="/musicVideo" component={MusicLibrary}/>
            <Route path="/tagVideo" component={TagLibrary}/>
            <Route path="/videos" component={PlayVideoSection}/>
            <Route path="/login" component={Login}/>
            <Route path="/register" component={Register}/>
            <Route path="/search" component={Search}/>
            <Route path="/uploadVideo" component={uploadVideo}/>
            <Route path="/u/:username" component={userProfile}/>
            {/*<Route path="/home" component={Home} exact/>
            <Route path="/about" component={About} exact/>
            <Route path="/privacy-policy" component={PrivacyPolicy} exact/>
            <Route path="/terms" component={TermCondition} exact/>*/}
            <Redirect to="/" />
        </Switch>
    );

    if(localStorage.getItem("user")) {
        if (userData.role_type == 1){
            routes = (
                <Switch>
                    <Route path="/kurafatadmin" component={Dashboard} exact/>
                    <Route path="/kurafatadmin/dashboard" component={Dashboard} exact/>
                    <Route path="/kurafatadmin/user" component={Users} exact/>
                    <Route path="/kurafatadmin/user_detail/:id" component={UserDetail} exact/>
                    <Route path="/kurafatadmin/createevent" component={CreateEvent} exact/>
                    <Route path="/kurafatadmin/tournaments" component={Tournaments} exact/>
                    <Route path="/kurafatadmin/tournamentdetail" component={TournamentDetail} exact/>
                    <Route path="/kurafatadmin/music" component={Music} exact/>
                    <Route path="/kurafatadmin/visitor" component={Visitor} exact/>
                    <Route path="/kurafatadmin/videos" component={Videos} exact/>
                    <Route path="/kurafatadmin/block_video" component={BlockedVideos} exact/>
                    <Route path="/kurafatadmin/createmusic" component={CreateMusic} exact/>
                    <Route path="/kurafatadmin/promotionalimages" component={PromotionalImages} exact/>
                    <Route path="/kurafatadmin/winners" component={Winners} exact/>
                    <Route path="/kurafatadmin/withdraw" component={Withdraw} exact/>
                    <Route path="/privacy-policy" component={PrivacyPolicy} exact/>
                    <Route path="/terms" component={TermCondition} exact/>
                    <Route path="/faq" component={Faq} exact/>
                    <Route path="/help-center" component={PrivacyPolicy} exact/>
                    <Route path="/about" component={PrivacyPolicy} exact/>
                    <Route path="/datadeletion" component={DataDeletion} exact/>
                    <Redirect to="/kurafatadmin" />
                    <Route path="/u/:username" component={userProfile}/>
                </Switch>
            );
        } else {
            routes = (
                <Switch>
                    <Route path="/" component={Home} exact/>
                    <Route path="/player" component={VideoPlayer} exact/>
                    <Route path="/kurafatadmin" component={AdminLogin} exact/>
                    <Route path="/privacy-policy" component={PrivacyPolicy} exact/>
                    <Route path="/terms" component={TermCondition} exact/>
                    <Route path="/faq" component={Faq} exact/>
                    <Route path="/help-center" component={PrivacyPolicy} exact/>
                    <Route path="/about" component={PrivacyPolicy} exact/>
                    <Route path="/datadeletion" component={DataDeletion} exact/>
                    <Route path="/musicVideo" component={MusicLibrary}/>
                    <Route path="/videos" component={PlayVideoSection}/>
                    <Route path="/uploadVideo" component={uploadVideo}/>
                    <Route path="/tagVideo" component={TagLibrary}/>
                    <Route path="/search" component={Search}/>
                    <Route path="/myProfile" component={MyProfile}/>
                    <Route path="/u/:username" component={userProfile}/>
                    <Redirect to="/" />
                </Switch>
            );
        }
    }


    return (
        <BrowserRouter>
            <div>
                {routes}
            </div>
        </BrowserRouter>

    );
}

export default Routes;