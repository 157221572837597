import axios from 'axios';
var base64 = require('base-64');

export const baseURL = 'https://kurafat.com/thinkadmin/api/v1/';
//export const baseURL = 'https://kurafat.examvote.com/api/v1/';
//export const baseURL = 'http://localhost/kurafat/api/v1/';

//export const mainURL = 'http://localhost:3000/';
 export const mainURL = 'https://kurafat.com/';


export const userData = JSON.parse(localStorage.getItem('user'))

export const fbData = JSON.parse(localStorage.getItem('fb_info'))

export const googleData = JSON.parse(localStorage.getItem('google_info'))

export const callAPI = async (url, method, data = null, content_type = null) => {
    try {
        let token = userData ? 'bearer '+userData.token : '';
        let response =  await axios[method](baseURL +'web/'+ url, data, {
            headers: {
                'Content-Type': content_type,   
                'Authorization': token
            }
        });
        return JSON.parse(base64.decode(response.data));
    } catch(err) {
        throw err;
    }
}

export const callAdminAPI = async (url, method, data = null, content_type = null) => {
    try {
        let token = userData ? 'bearer '+userData.token : '';
        return await axios[method](baseURL + url, data, {
            headers: {
                'Content-Type': content_type,
                'Authorization': token
            }
        });
    } catch(err) {
        throw err;
    }
}