import React from 'react';

const TermCondition =() =>{
  return(
    <>
      <div className="terms-page">
        <h3 className="terms-heading">Terms of Use</h3>
        <div className="container">
          <p>The following terms of service constitute a binding legal agreement (the “Agreement”) between You and kurafat. (hereinafter referred to as “kurafat” / “we”/ “us”). Kurafat is a product by ThinkStart Pvt Ltd, Setting forward the terms and conditions under which the kurafat (the “Application”) and any relevant services will be licensed to You by us.</p>

          <p>By downloading, installing the Application, You exemplify to kurafat that you are qualified to enter into a contract (i.e. You a major according to applicable law, of sound mind and not disqualified from entering into a contract under the applicable law- according to Indian contract Act, 1872) and You have read this Agreement, understand it and agree to be bound by its Terms. Please analyze the Agreement carefully before accessing, downloading, and installation.</p>

          <p>Our Services (as we have described below in detail) and these Terms are amenable under the Indian Penal Code, 1860, and the Information Technology Act, 2000, including all amendments made to it and rules framed under it. When you use our Platform, you accept and agree to these Terms. However, please note that we do not represent that we are compliant with the laws of any country apart from the Republic of India. If you wish to use our Services, please ensure that you are permitted to do so, in your jurisdiction within the law.</p>

          <h4>The Application: kurafat's Service </h4>
          <p>The kurafat Application and related services are currently made available to you free of any charge. The Kurafat Application is a messaging, challenge providing event and short video creating a platform that allows users to send text messages, share photos, videos, etc. over the internet. kurafat reserves the right to discontinue or withdraw this Application, at any time, without notice. More information about the Application is available on kurafat’s website www.kurafat.com (“Site”).

            <h4>About our services</h4>
            <p>Our application gives users the Platform to upload, post or create or otherwise make available content through the Platform including - without limitation, any photographs, user videos, sound recordings including videos that incorporate locally stored sound recordings from your personal music library and ambient noise (“User Content”).</p>

            <p>When you publish any User Content on the Platform, you continue to have whatever ownership rights in that content you had to begin with. But you grant us a license to use that content for the purpose of providing you the Service. you also agree to give other users the right to share or transfer such User Content on the kurafat platform or off the platform, based on functionalities provided by us.</p>

            <h4>Who may use our service </h4>
            <p>Our Platform helps you to stay in touch with your friends and enables you to share any photographs, user videos, sound recordings, challenges, and events including videos that incorporate locally stored sound recordings from your personal music library and ambient noise. We understand your preferred content and suggest content available on our Platform in an automated manner without the involvement of any of our personal (“Service/Services”).</p>

            <p>You may use our Services only if you are capable of forming a binding agreement with us and are legally permitted to use our Services. If you are accepting these Terms on behalf of a company or any legal persons, then you represent and warrant that you have the authority to bind such entities to these Terms, and effectively “you” and “your” shall refer to the company.</p>
            <p>Please ensure that you are allowed to use our services under the law.</p>

            <h4> How to use our service</h4>
            <p>We have developed a unique platform. We allow you to upload, create or download content that is available on our Platform and share your kurafat experience across social media platforms.</p>
            <p>To use our Services, we may ask you to - </p>
            <ul>
              <li>	Register - You must register for our Services using accurate data, provide your current mobile phone number, and, if you change it, update this mobile phone number. You agree to receive text messages and phone calls (from us or our third-party providers) with codes to register for our Services.</li>
              <li>	Location permission - you must provide kurafat with your current location so that we can provide you with better services, which include joining with events happening around you and watching videos created nearby you.</li>
              <li>	Storage permission - storage permission is required to access your camera, Gallery to make it easy for you to upload and share photos and videos</li>
            </ul>


            <h4>Your Conduct</h4>
            (a) You shall be solely responsible for all contents that You upload, transmit, share or display through the Application or the Site, (collectively the “Contents”) including but not limited to any photos, profiles (including Your name and image), messages, information, text, video, music, third party links. Kurafat does not guarantee as to the validity, accuracy or legal status, or confidentiality with respect to any Contents, and You shall be solely responsible and assume all risks for any consequences of uploading, posting, transfer, or disclosure of the Contents. You hereby confirm and warrant that the Contents do not violate the representation and warranties provided.
            (b) You represent and guarantee that: (i) your use of the Application or the Services will be solely for purposes that are permitted by these Terms; (iii) you will provide true and accurate information (including your mobile phone number and location ) for use of the Application or the Services; (iv) your use of the Application or the Services will at all times comply with applicable laws.
            (c) You must not use the Application or the Services for fraudulent purposes or in connection with a criminal offense or other unlawful activity or to send, post, share, use or reuse any material that does not belong to you or that you are not authorized to send; or is illegal, offensive (including but not limited to material that is sexually explicit content or which promotes racism, bigotry, hatred or physical harm), deceptive, misleading, abusive, indecent, harassing, blasphemous, defamatory, libelous, obscene, pornographic, pedophilic or menacing; ethnically objectionable, disparaging or in breach of copyright, trademark, confidentiality, privacy or any other proprietary information or right; or is otherwise injurious to third parties; or relates to or promotes money laundering or gambling; or is harmful to minors in any way; or impersonates another person; or threatens the unity, integrity, security or sovereignty of India or friendly relations with foreign States; or objectionable or otherwise unlawful in any manner.

            <h4>Platform Not to be Used for Anything Unlawful or Illegal</h4>
            <p>Our Platform is designed to accommodate a multiplicity of languages and cultures, as well as a diverse range of contents. To this effect, we have developed various tags to classify the nature of the content.</p>

            <p>You must, therefore, correctly identify the nature of the content shared by you and tag it appropriately. You shall not, however, use our Platform to share any content which is obscene, pornographic, harmful for minors, discriminatory, spreading hate speech, inciting any form of violence or hatred against any persons, or of seditious in nature, or violates any laws of the Republic of India, or is barred from being shared by any laws of the Republic of India. We reserve the right to remove such content. Please read the kurafat Content and Community Guidelines for further details.</p>

            <p>In addition to the above, please note that we may share your information with appropriate law enforcement authorities if we have a good-faith belief that it is reasonably necessary to share your personal data or information in order to comply with any legal obligation or any government request; or to protect the rights or prevent any harm to our property or safety, our customers, or public; or to detect, prevent or otherwise address public safety, fraud, security or technical issues. </p>

            <p>You understand, however, that we cannot be held responsible for any actions done by or to you by a third party or user by way of using our Platform.</p>

            <p><strong>We have developed a platform for people to come together; please do not share any content which is illegal or causes any harm to the well-being of members of the society or community.</strong></p>

            <h4>Application Stores</h4>

            <p>The Application can also be downloaded from third-party application stores including Google Play, kurafat shall not be responsible for violation and infringement of any third party right, terms, and conditions in connection with the access, download, or installation of the Application by You.</p>

            <h4>Intermediary Status and No Liability</h4>
            We are an intermediary under the Information Technology Act, 2000 and the Information Technology Act (Intermediary Guidelines) Rules, 2011. These Terms are published in accordance with the provisions of Rule 3(1) of the Information Technology (Intermediaries Guidelines) Rules, 2011 that require publishing of the rules and regulations, kurafat Privacy Policy, and kurafat Terms of Use for accessing and using our Platform. Our role is limited to providing a platform to display the content shared by you and our other users.</p>

          <p>We do not control what people do or say and are not responsible for their (or your) actions (whether online or offline). We are not responsible for services and features offered by others, even if you access them through our Services. Our responsibility for anything that happens on our Platform is strictly governed by the laws of the Republic of India and is limited to that extent. You agree that we will not be responsible for any loss of profits, revenues, information, or data, or consequential, special, indirect, exemplary, punitive, or incidental damages arising out of or related to these Terms, even if we know they are possible. This includes when we delete your content, information, or account.</p>

          <p><strong> We are an intermediary under Indian law. We do not control what people post on our Platform but we expect everyone to comply with the kurafat Content and Community Guidelines. </strong></p>


          <h4>Contents</h4>
          <p>You shall be entirely responsible for all contents that You upload, transmit, share or display through the Application or the Site, including but not limited to any photos, profiles (including Your name and image), messages, information, text, video, music, or third party links.
            Modifications to the Application</p>
          <p>Kurafat stores the right at any time and from time to time to interrupt, restrict (without cause and without notice to You), modify or discontinue, temporarily or permanently, the Site, Application (or any part thereof) with or without any notice. You agree that kurafat shall not be liable to You or any third party for any modification, suspension, or discontinuance of the Application or the Site.</p>

          <h4>Amendments to this Agreement</h4>
          <p>We may in our sole discretion amend this Agreement from time to time without any prior notice. This may include adding new or different terms to, or removing terms from, this Agreement. Such change shall be notified on the website www.kurafat.com. Your use of the Application after such notice of any change shall be deemed to be an acceptance to the amended Agreement.</p>

          <h4>General</h4>
          <ul>
            <li> If any aspect of these Terms is unenforceable, the rest will remain in effect.</li>
            <li> Any amendment or waiver to our Terms must be in writing and signed by us.</li>
            <li>If we fail to enforce any aspect of these Terms, including reporting any illegal or impermissible actions to appropriate law enforcement authorities or blocking or suspending your profile, such failure to enforce our rights will not be a waiver by us.</li>
            <li>We reserve all rights not expressly granted to you.</li>
          </ul>

          <h4>Verified user badge</h4>
          <ul>
            <li> Unique and Creative content.</li>
            <li> Minimum 10 Videos posted.</li>
            <li> Minimum 10 referrals.</li>
            <li>You need to share a government ID Over Email for your account verification which needs to match your profile details.</li>
            <li>We look at some factors when evaluating Kurafat accounts to determine if they're in the public interest and meet our verification criteria.</li>
          </ul>

          <h4>Contact kurafat</h4>
          <p>If You have any questions or skepticism about this Privacy Policy/ terms of use or its enactment, You may contact us on the following e-mail ID:</p>
          <p><strong>E-mail: </strong>iamkurafati@kurafat.com  </p>

        </div>
      </div>
    </>
  );
};



export default TermCondition;