import  React from 'react';
import '../../Assets/css/Footer.scss';
import {Facebook, Instagram, Pinterest, Twitter} from "@material-ui/icons";

class Footer extends React.Component {

  render() {
  return(
    <> 
     <footer className="footer">
      <div className="container">
        <div className="row">
         <div className="col-10 mx-auto">
          <div className="footer-social-icons">
           <ul>
               <li><a href="https://www.instagram.com/iamkurafati/"><Instagram fontSize="large"/></a></li>
               <li><a href="https://twitter.com/iamkurafati"><Twitter fontSize="large" /></a></li>
             <li><a href="https://www.facebook.com/iamkurafati"><Facebook fontSize="large" /></a></li>
             <li><a href="https://in.pinterest.com/iamkurafati"><Pinterest fontSize="large" /></a></li>
           </ul>   
          </div>
          <div className="bootom-menu">
           <a href={'terms'}> Terms of Use</a>
           <span className="remove">|</span>
           <a href={'privacy-policy'}>Privacy Policy</a>
          </div>
         </div>
         <div className="copy-right text-center pt-5">
         © 2021 Kurafat, All rights reserved
         </div>
        </div>
      </div>       
     </footer>
    </>
  );
}
}


export default Footer;