import React from 'react';
import swal from "sweetalert";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import {callAdminAPI} from "../../../Commen/CommenFunction";
import dummyImg from '../../../Assets/img/dummy_user.png';
import {Link} from "react-router-dom";
import $ from "jquery";
import dt from "datatables.net";
import Sidebar from "../Layout/Sidebar";


class Music extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            musics:[],
        }
    }

   async componentDidMount() {
        try {
            this.setState({
                loading:true
            });
            const response = await callAdminAPI('admin/musicList', 'post','application/json');

            if (response.data.status === true) {
                if (response.data.status_code == 0){
                    this.setState({
                        musics:response.data.data.data
                    });
                }
            }
            this.setState({
                loading:false
            });
            $('.dataTable-data').dataTable( {
                "ordering": false
            } );
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        return(
            <div id="wrapper">
                <Sidebar/>
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Header loading={this.state.loading} />
                        <div className="content-wrapper">
                            <section className="content-header">
                                <div className="container-fluid">
                                    <div className="row mb-2">
                                        <div className="col-sm-6">
                                            <h1>Music List</h1>
                                        </div>
                                        <div className="col-sm-6">
                                            <ol className="breadcrumb float-sm-right">
                                                <li className="breadcrumb-item"><a href={'/kurafatadmin'}>Home</a></li>
                                                <li className="breadcrumb-item active">Music</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section className="content">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <a href={'createmusic'} className="btn btn-success float-right"><i className="fa fa-plus"></i> Create </a>
                                                </div>
                                                <div className="card-body">
                                                    <table id="example2" className="table table-bordered table-hover dataTable-data">
                                                        <thead>
                                                        <tr>
                                                            <th>Sr. No.</th>
                                                            <th>Name</th>
                                                            <th>Image</th>
                                                            <th>Category</th>
                                                            <th>File</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            this.state.musics.map((music, key )=> {
                                                                return (
                                                                    <tr key={key}>
                                                                        <td>{++key}</td>
                                                                        <td>{music.title}</td>
                                                                        <td><img height={50} width={50} src={music.image_url ? music.image_url : dummyImg } /></td>
                                                                        <td>{music.category ? music.category.title : ''}</td>
                                                                        <td>
                                                                            <audio controls>
                                                                                <source src={music.sound_url} type="audio/mpeg" />
                                                                            </audio>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        );
    }
}

export default Music;