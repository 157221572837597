import React from 'react';
//import '../../../Assets/css/adminlte.css';
import blueIcon from '../../../Assets/img/blue-icon.svg';
import swal from "sweetalert";
import {userData} from "../../../Commen/CommenFunction";

class Sidebar extends React.Component{

    render() {
        let pathUrl = window.location.pathname.split('/')[2];
        return(
            <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
                <a className="sidebar-brand d-flex align-items-center justify-content-center" href={'/'}>
                    <div className="sidebar-brand-icon rotate-n-15">
                        <i className="fas fa-laugh-wink"></i>
                    </div>
                    <div className="sidebar-brand-text mx-3">Kurafat</div>
                </a>
                <hr className="sidebar-divider my-0"/>
                <li className="nav-item active">
                    <a href={'/kurafatadmin'} className={ !pathUrl || pathUrl == "" ? "nav-link active" : "nav-link" } href="index.html">
                        <i className="fas fa-fw fa-tachometer-alt"></i>
                        <span>Dashboard</span></a>
                </li>
                <li className="nav-item">
                    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUtilities"
                       aria-expanded="true" aria-controls="collapseUtilities">
                        <i className="fas fa-fw fa-video"></i>
                        <span>Videos</span>
                    </a>
                    <div id="collapseUtilities" className="collapse" aria-labelledby="headingUtilities"
                         data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <a href={'/kurafatadmin/videos'} className="collapse-item" >Active Video</a>
                            <a href={'/kurafatadmin/block_video'} className="collapse-item">Blocked Videos</a>
                        </div>
                    </div>
                </li>

                <li className="nav-item">
                    <a href={'/kurafatadmin/user'} className={ pathUrl == 'user' ? "nav-link active" : "nav-link" }>
                        <i className="fas fa-fw fa-user"></i>
                        <span>Users</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a href={'/kurafatadmin/tournaments'} className={ pathUrl == 'tournaments' ? "nav-link active" : "nav-link" }>
                        <i className="fas fa-fw fa-user"></i>
                        <span>Tournaments</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a href={'/kurafatadmin/music'} className={ pathUrl == 'music' ? "nav-link active" : "nav-link" }>
                        <i className="fas fa-fw fa-user"></i>
                        <span>Music</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a href={'/kurafatadmin/promotionalimages'} className={ pathUrl == 'promotionalimages' ? "nav-link active" : "nav-link" }>
                        <i className="fas fa-fw fa-user"></i>
                        <span>Promotional Images</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a href={'/kurafatadmin/winners'} className={ pathUrl == 'winners' ? "nav-link active" : "nav-link" }>
                        <i className="fas fa-fw fa-user"></i>
                        <span>Winners</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a href={'/kurafatadmin/withdraw'} className={ pathUrl == 'withdraw' ? "nav-link active" : "nav-link" }>
                        <i className="fas fa-fw fa-user"></i>
                        <span>Withdraw</span>
                    </a>
                </li>
                {/*
                <li className="nav-item">
                    <a href={'/kurafatadmin/visitor'} className={ pathUrl == 'visitor' ? "nav-link active" : "nav-link" }>
                        <i className="fas fa-fw fa-user"></i>
                        <span>Visitors</span>
                    </a>
                </li>*/}
            </ul>
        );
    }
}

export default Sidebar;