import React from 'react';
import '../../Assets/css/mobile_web.css';
import { callAPI} from "../../Commen/CommenFunction";
import dummyImg from '../../Assets/img/dummy_user.png';
import videoIcon from '../../Assets/img/video-icon.svg';
import $ from "jquery";
import {Link} from "react-router-dom";


class MusicLibrary extends React.Component{

    constructor(props) {
        super(props);
        this.state={
            loading:true,
            music:[],
            post:[],
            page:1,
            scrollHeight:300,
            found:false,
            next_page:true
        };
    }

    async componentDidMount() {
        this.setState({
            music:this.props.history.location.state.music
        });

        $('body').addClass('theme_bg');

        await this.getPublishedPost();

        window.addEventListener('scroll', this.handleScroll, true);
    }

    handleScroll = async () => {
        let header = $('.cat-info-row');
        if (window.pageYOffset > 80) {
            header.addClass("sticky");
        } else {
            header.removeClass("sticky");
        }
        if (window.pageYOffset > this.state.scrollHeight && this.state.next_page){
            let height = this.state.scrollHeight+600;
            this.setState({
                scrollHeight:height,
            });
            await this.getPublishedPost();
        }
    }

    async getPublishedPost() {
        const formData = new FormData()
        formData.set('sound_id', this.props.history.location.state.music.id)
        formData.set('page', this.state.page)
        try {
            let response = await callAPI('videoByMusic', 'post', formData, 'application/json');
            if (response.status){
                response.data.data.forEach(el => {
                    this.state.post.push(el)
                });
                let next_page = false
                if (response.data.next_page_url){
                    next_page = true;
                }

                this.setState({
                    post:this.state.post,
                    loading:false,
                    found:true,
                    page:this.state.page+1,
                    pageYOffset:this.state.pageYOffset+600,
                    next_page : next_page,
                });
            } else {
                this.setState({
                    loading:false,
                    found:false
                });
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    render() {
        return (
            <div>
                <div className="mb-container  vh-100 pag-tp-pt">
                    <div className="back_arrow"><a href='#' onClick={this.props.history.goBack}><i className="fa fa-arrow-left" aria-hidden="true"></i></a></div>
                    <div className="cat-info-row" id="pro-row-fix">
                        <div className="cat-img">
                            <img src={this.state.music.image_url} className="rounded img-fluid"/>
                        </div>
                        <div className="cat-info">
                            <div className="user-name-id">
                                <h3>{this.state.music.title}</h3>
                                <div className="userid">{this.state.music.post_count} Video</div>
                            </div>
                        </div>
                    </div>
                    <ul className="user-vd-list">
                        {
                            this.state.post.map((post, key )=> {
                                return (
                                    <li className="vd-list-item" key={key}>
                                        <div className="vd-like-count">Like: {post.like_count}</div>
                                        <Link  to={{
                                            pathname: 'videos',
                                            state: {
                                                videos:this.state.post,
                                                index:key,
                                                page:this.state.page,
                                                sound_id:this.state.music.id,
                                                video_type: 'sound',
                                                currentVideo:post
                                            }
                                        }}>
                                            <div className="vd-sign">
                                                <img src={videoIcon} alt="" className="img-fluid" />
                                            </div>
                                        </Link>
                                        <img src={post.thumb_url} className="img-fluid" alt=""/>
                                    </li>
                                )
                            })}
                    </ul>
                </div>
            </div>
        );
 }
}

export default MusicLibrary;