import React from 'react';
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import {callAdminAPI} from "../../../Commen/CommenFunction";
import Sidebar from "../Layout/Sidebar";
import dummyImg from "../../../Assets/img/dummy_user.png";


class Visitor extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            visitor:[],
        }
    }

   async componentDidMount() {
        try {
            this.setState({
                loading:true
            });
            const response = await callAdminAPI('admin/visitor', 'post','application/json');

            if (response.data.status === true) {
                if (response.data.status_code == 0){
                    this.setState({
                        visitor:response.data.data
                    });
                }
            }
            this.setState({
                loading:false
            });
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        return(
            <div id="wrapper">
                <Sidebar/>
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Header loading={this.state.loading} />
                        <div className="content-wrapper">
                            <section className="content-header">
                                <div className="container-fluid">
                                    <div className="row mb-2">
                                        <div className="col-sm-6">
                                            <h1>Visitor</h1>
                                        </div>
                                        <div className="col-sm-6">
                                            <ol className="breadcrumb float-sm-right">
                                                <li className="breadcrumb-item"><a href={'/kurafatadmin'}>Home</a></li>
                                                <li className="breadcrumb-item active">Visitor</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section className="content">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-4">
                                            <div className="card">
                                                <div className="card-header">Total Visitor</div>
                                                <div className="card-body">{this.state.visitor.total}</div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="card">
                                                <div className="card-header">Today Visitor</div>
                                                <div className="card-body">{this.state.visitor.today}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        );
    }
}

export default Visitor;