import React from 'react';
import '../../Assets/css/button.scss';
import '../../Assets/css/Home.scss';
import {baseURL, callAPI, userData} from "../../Commen/CommenFunction";
import Header from "../Layout/Header";
import MetaTags from 'react-meta-tags';
import dummyImg from '../../Assets/img/dummy_user.png';
import videosignpic from '../../Assets/img/videosign.png';
import commentsvg from '../../Assets/img/comment-icon.svg';
import likesvg from '../../Assets/img/likeicon.svg';
import googleBtn from '../../Assets/img/googlebtn.png';
import loadingIcon from '../../Assets/img/loading-icon.gif';
import axios from "axios";
import Loading from "../Layout/Loading";
import NotFound from "../Layout/NotFound";


class webUserProfile extends React.Component{

    constructor(props) {
        super(props);
        this.state={
            loading:true,
            user:[],
            post:[],
            found:false
        };
    }

    async componentDidMount() {
        const formData = new FormData()
        formData.set('username', this.props.user.username)
        try {
            let response = await callAPI('userProfile', 'post', formData, 'application/json');
            if (response.status){
                this.setState({
                    user:response.data,
                    post:response.data.get_top_post,
                    loading:false,
                    found:true
                });
            } else {
                this.setState({
                    loading:false,
                    found:false
                });
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    render() {
        if(this.state.loading) {
            return (
                <Loading />
            );
        } else if(this.state.found) {
            return (
                <div>
                    <MetaTags>
                        <title>{this.state.user.name+" (@"+this.state.user.username+") . Kurafat Posts"}</title>
                        <meta name="description" content={this.state.user.follower_count+" Followers, "+this.state.user.following_count+" Following, "+this.state.user.post_count+" Posts - See Kurafat Posts from "+this.state.user.name+" (@"+this.state.user.username+")"} />
                        <meta property="og:title" content={this.state.user.name+" (@"+this.state.user.username+") . Kurafat Posts"} />
                        <meta property="og:image" content={this.state.user.image_url} />
                    </MetaTags>
                    <Header />
                    <div className="container cont-mini">
                        <div className="col-md-11 mx-auto">
                            <div className="user-info-row">
                                <div className="user-pic">
                                    <img src={this.state.user.image_url ? this.state.user.image_url : dummyImg} alt="profile" className="img-fluid rounded-circle" />
                                </div>
                                <div className="user-info-content">
                                    <div className="user-name-info">{this.state.user.username} <a href="#"
                                                                                                  className="mini-btn ml-3">Follow</a></div>
                                    <ul className="user-fam-info">
                                        <li><a href="#"> <span>{this.state.user.post_count}</span> Posts</a></li>
                                        <li><a href="#"><span>{this.state.user.follower_count}</span> Followers</a></li>
                                        <li><a href="#"><span>{this.state.user.following_count}</span> Following</a></li>
                                    </ul>
                                    <div className="user-des-txt">
                                        {this.state.user.about}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container cont-mini">
                        <div className="col-md-11 mx-auto">
                            <div className="row">
                                {
                                    this.state.post.map((post, key )=> {
                                        return (
                                            <div className="col-12 col-sm-4" key={key}>
                                                <div className="video-cont-col">
                                                    <img src={post.thumb_url} alt="post" className="img-fluid post-img" />
                                                    <div className="video-sign" data-toggle="modal" data-target="#downloadModel">
                                                        <a href="#"><img src={videosignpic} alt="videosign" className="img-fluid" /></a>
                                                    </div>
                                                    <div className="com-like-row">
                                                        <div>
                                                            <img src={commentsvg} alt="comment" className="img-fluid" /> {post.comment_count}
                                                        </div>
                                                        <div>
                                                            <img src={likesvg} alt="like" className="img-fluid" /> {post.like_count}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                            </div>
                        </div>
                    </div>
                    <div className="container cont-mini">
                        <div className="col-md-11 mx-auto">
                            <div className="download-sect">
                                <h3>Download App</h3>
                                <p className="mb-4 down-txt">You may download app from google play store or click below link
                                    to download</p>
                                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.iamkurafati&hl=en_IN&gl=US"><img src={googleBtn} alt="googleBtn"
                                                              className="img-fluid google-store-img mx-auto d-block" /></a>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade modal-radius" id="downloadModel" tabIndex="-1" role="dialog"
                         aria-labelledby="downloadModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="container cont-mini">
                                        <div className="col-md-11 mx-auto">
                                            <div className="download-sect">
                                                <h3>Download App</h3>
                                                <p className="mb-4 down-txt">You may download app from google play store or click below link
                                                    to download</p>
                                                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.iamkurafati&hl=en_IN&gl=US"><img src={googleBtn} alt="googleBtn"
                                                                                                                                                            className="img-fluid google-store-img mx-auto d-block" /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <NotFound />
            )
        }
 }
}

export default webUserProfile;