import React from 'react';

const DataDeletion =() =>{
  return(
    <>

      <div className="terms-page">
        <h3 className="terms-heading"><strong>Data Deletion </strong></h3>
        <div className="container">
          <h2>How to clear your data from the application-</h2>
          <ul>
            <li> 1.) Open the Settings application on your device.</li>
            <li> 2.) Head over to Apps Menu.</li>
            <li> 3.) Choose Installed Applications.</li>
            <li> 4.) Find the Application you want to Clear the App Data.</li>
            <li> 5.) Select it, Move to the Storage usage Tab.</li>
            <li> 6.) Hit Clear data / Clear Cache.</li>
          </ul>

          <br/>
          <br/>
          <h2>How to clear your data from the App Icon-</h2>
          <ul>
            <li> 1.) On your Android phone or tablet device.</li>
            <li> 2.) Tap Application Icon you want to clear the App Data. </li>
            <li> 3.) Choose App Info.</li>
            <li> 4.) Select it, Move to the Storage usage Tab.</li>
            <li> 5.) Hit Clear data / Clear Cache.</li>
          </ul>
        </div>
      </div>
    </>
  );
};



export default DataDeletion;