import React from 'react';
import swal from "sweetalert";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import {callAdminAPI} from "../../../Commen/CommenFunction";
import dummyImg from '../../../Assets/img/dummy_user.png';
import {Link} from "react-router-dom";
import $ from "jquery";
import dt from "datatables.net";
import Sidebar from "../Layout/Sidebar";


class Tournaments extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            events:[],
        }
    }

   async componentDidMount() {
        try {
            this.setState({
                loading:true
            });
            const response = await callAdminAPI('admin/eventList', 'post','application/json');

            if (response.data.status === true) {
                if (response.data.status_code == 0){
                    this.setState({
                        events:response.data.data
                    });
                }
            }
            this.setState({
                loading:false
            });
            $('.dataTable-data').dataTable( {
                "ordering": false
            } );
        } catch (e) {
            console.log(e);
        }
    }

    async deleteEvent(id){
        swal({
            title: "Are you sure?",
            text: "You will not be able to recover this tournament!",
            icon: "warning",
            buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
            ],
            dangerMode: true,
        }).then(async function(isConfirm) {
            if (isConfirm) {
                const form = new FormData()
                form.set('id', id)
                const response = await callAdminAPI('admin/deleteAdminEvent', 'post',form,'application/json');
                if (response.data.status === true) {
                    window.location.reload();
                }
            } else {
                swal("Cancelled", "Your tournament is safe :)", "error");
            }
        })
    }

    render() {
        return(
            <div id="wrapper">
                <Sidebar/>
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Header loading={this.state.loading} />
                        <div className="content-wrapper">
                            <section className="content-header">
                                <div className="container-fluid">
                                    <div className="row mb-2">
                                        <div className="col-sm-6">
                                            <h1>Tournament List</h1>
                                        </div>
                                        <div className="col-sm-6">
                                            <ol className="breadcrumb float-sm-right">
                                                <li className="breadcrumb-item"><a href={'/kurafatadmin'}>Home</a></li>
                                                <li className="breadcrumb-item active">Tournaments</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section className="content">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <a href={'createevent'} className="btn btn-success float-right"><i className="fa fa-plus"></i> Create </a>
                                                </div>
                                                <div className="card-body">
                                                    <table id="example2" className="table table-bordered table-hover dataTable-data">
                                                        <thead>
                                                        <tr>
                                                            <th>Sr. No.</th>
                                                            <th>Name</th>
                                                            <th>Image</th>
                                                            <th>Type</th>
                                                            <th>Start Date</th>
                                                            <th>End Date</th>
                                                            <th>Date of Creation</th>
                                                            <th>Action</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            this.state.events.map((event, key )=> {
                                                                return (
                                                                    <tr key={key}>
                                                                        <td>{++key}</td>
                                                                        <td>{event.title}</td>
                                                                        <td><img height={50} width={50} src={event.thumb_file_url ? event.thumb_file_url : dummyImg } /></td>
                                                                        <td>{event.type == 1 ? 'Event' : 'Challenge'}</td>
                                                                        <td>{event.start_time}</td>
                                                                        <td>{event.end_time}</td>
                                                                        <td>{event.created_at}</td>
                                                                        <td><Link  to={{
                                                                            pathname: "/kurafatadmin/tournamentdetail",
                                                                            state: event
                                                                        }}><button className="btn btn-success"><i className="fa fa-eye"></i></button></Link>
                                                                            <button className="btn btn-danger" onClick={()=>this.deleteEvent(event.id)}><i className="fa fa-trash"></i></button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        );
    }
}

export default Tournaments;