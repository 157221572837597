import React from 'react';
import $ from "jquery";
import GoogleLogin from 'react-google-login';
import {callAPI, userData} from "../../Commen/CommenFunction";
import { withRouter } from "react-router-dom";


class LoginPopup extends React.Component {
    removeLoginPopup(){
        $('.login-opt-slide').removeClass('active');
    }
  render() {
    return(
        <>
          <div className="login-opt-slide">
            <div className="cls-btn" onClick={()=>this.removeLoginPopup()}><i className="fas fa-times"></i></div>
                <h3>Login/Sign up</h3>
            <p className="mb-4"><a href={'login'} className="lgn_sign-btn"><i
                className="fas fa-mobile-alt"></i> Continue with Phone</a></p>
            Read and agree to our <a href="#">Privacy Policy</a> and <a href="#">Terms of Use</a>
          </div>
        </>
    )
  }
}


export default withRouter(LoginPopup);