import React, { useRef, useState } from 'react';
import './Video.css';
import VideoFooter from "../videoFooter/VideoFooter"
import VideoSidebar from '../videoSidebar/VideoSidebar';
import $ from "jquery";
import useInView from "react-cool-inview";
import { useDoubleTap, DoubleTapOptions } from 'use-double-tap';
import {LinearProgress} from "@material-ui/core";
import CommentSection from "../../mobile/CommentSection";
import {callAPI, userData} from "../../../Commen/CommenFunction";
import heartIcon from "../../../Assets/img/heart-animation.gif";



function Video({url,song, description, channel, likes, messages, view, thumb, currentPage, liked, dataId, video}) {

  const [playing, setPlaying] = useState(false);
  const [heart, setHeart ] = useState(false);
  const [likeData, setLiked ] = useState(liked);

  const { ref, inView, scrollDirection, entry, observe, unobserve } = useInView({
        onChange: ({ inView, scrollDirection, entry, observe, unobserve }) => {
          // Triggered whenever the target meets a threshold, e.g. [0.25, 0.5, ...]
        },
        onEnter: ({ scrollDirection, entry, observe, unobserve }) => {
            if (entry.target.getAttribute('data-id') >= currentPage) {
                entry.target.play();
            }
        },
        onLeave: ({ scrollDirection, entry, observe, unobserve }) => {
          entry.target.pause();
          entry.target.currentTime = 0;
        },
      }
  );

  const onVideoPress = () => {
      for (let i = 0 ; i < $('video').length ; i++){
          $('video')[i].muted = false;
          $('.unmute-video').hide();
      }
    if(playing) {
      ref.current.pause();
      setPlaying(false)
    } else {
      ref.current.play();
      setPlaying(true)
    }
  }

    const bind = useDoubleTap(async (event) => {

        liked = true;
        setLiked(true)

        if(playing) {
            ref.current.play();
            setPlaying(false)
        } else {
            ref.current.pause();
            setPlaying(true)
        }
        setHeart(true)


        if (userData){
            const formVideoData = new FormData()
            formVideoData.set('post_id', video.id)
            try {
                let response = await callAPI('likePost', 'post', formVideoData, 'application/json');
            } catch (e) {
                console.log(e);
            }
        }

    });

    setTimeout(() => {
        setHeart(false)
    }, 3000);

  return (

      <div className="video" {...bind}>
        <video className="video__player" playsInline={true}
               loop={true}
               src={url}
               poster={thumb}
               ref={ref} onClick={onVideoPress}
               data-id={dataId}
               muted={true}
        >
        </video>
        <VideoFooter channel={channel} description={description} song={song}/>
        <VideoSidebar messages={messages} view={view} likes={likes} user={channel} thumb={thumb} likedVideo={likeData} video={video}/>
          {heart && <div className='heart-animation'><img src={heartIcon}/></div> }
      </div>
  )
}

export default Video
