import React from 'react';
import '../../Assets/css/mobile_web.css';
import {callAPI, userData} from "../../Commen/CommenFunction";
import searchImg from '../../Assets/img/search-bar.svg';
import closeImg from '../../Assets/img/close-bar.svg';
import $ from "jquery";
import { withRouter } from "react-router-dom";
import Footer from "./Footer";
import Swiper from 'react-id-swiper';
import dummyUser from "../../Assets/img/dummy_user.png";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Link} from "react-router-dom";

class Search extends React.Component{

    constructor(props) {
        super(props);
        this.state={
            tags: [],
            users:[],
            search:'',
            loading:false,
        };
    }

    async inputChangeHandler(event, key) {
        const value = event.target.value;
        await this.searchFunction(value);
    }

    async searchFunction(value) {
        this.setState({
            loading:true,
            search:value
        });
        let href = $('.search-tab').find('.active').attr("href");
        if(href == '#tag-tab'){
            await this.getSearchTagData(1, value);
        } else if (href == '#star-tab') {
            await this.getSearchUserData(1, value);
        }
    }

    async componentDidMount() {
        $('body').addClass('theme_bg');
        $('.mobile-bottom-nav').addClass('theme_black');
        this.setState({
            loading:true
        });
       await this.getSearchTagData(1, this.state.search)
    }

    async getSearchTagData(page, search){
        const formVideoData = new FormData()
        formVideoData.set('page', page)
        formVideoData.set('search', search)
        formVideoData.set('search_type', 'H')
        try {
            let response = await callAPI('search', 'post', formVideoData, 'application/json');
            if (response.status) {
                if (response.status_code == 1){
                    this.setState({
                        found: false,
                        paginate: false,
                        tags: [],
                        loading:false
                    });
                } else {
                    this.setState({
                        tags: response.data.data,
                        paginate: false,
                        loading:false,
                        page:this.state.page+1,
                        last_page:response.data.last_page
                    });
                }
            } else {
                this.setState({
                    found: false,
                    paginate: false,
                    loading:false
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    async getSearchUserData(page, search){
        const formVideoData = new FormData()
        formVideoData.set('page', page)
        formVideoData.set('search', search)
        formVideoData.set('search_type', 'U')
        try {
            let response = await callAPI('search', 'post', formVideoData, 'application/json');
            if (response.status) {
                if (response.status_code == 1){
                    this.setState({
                        found: false,
                        paginate: false,
                        users: [],
                        loading:false
                    });
                } else {

                    this.setState({
                        users: response.data.data,
                        paginate: false,
                        loading:false,
                        page:this.state.page+1,
                        last_page:response.data.last_page
                    });
                }
            } else {
                this.setState({
                    found: false,
                    paginate: false,
                    loading:false
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    async getUsers() {
        this.setState({
            loading:true
        });
        await this.getSearchUserData(1,this.state.search)
    }
    async getTags() {
        this.setState({
            loading:true
        });
        await this.getSearchTagData(1,this.state.search)
    }

    async clearSearch() {
        await this.searchFunction('');
    }

    playVideoSection(tag, post) {
        this.props.history.push({
            pathname: '/tagVideo',
            state: { tag:tag.name, video:post }
        });
    }

    hashtagSection(tag) {
        this.props.history.push({
            pathname: '/tagVideo',
            state: { tag:tag.name }
        });
    }
    render() {

        const params = {
            grabCursor: true,
            slidesPerView: 1,
            spaceBetween: 10,
            loop: true,
            breakpoints: {
                1200: {
                    slidesPerView: 6,
                    spaceBetween: 40,
                },
                1024: {
                    slidesPerView: 5,
                    spaceBetween: 40,
                },
                768: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                },
                640: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                320: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
            },
        };

        return (
            <>
            <div className="mb-container vh-100 pag-tp-pt search-page">
                <div className="searchwrapper">
                    <div className="search-input-gp">
                        <img src={searchImg} className="mr-2 img-fluid" />
                        <input type="text" name="" placeholder="Search here..." value={this.state.search}
                               onChange={(event) => this.inputChangeHandler(event, 'search')} />
                        <img src={closeImg} className="ml-2 img-fluid" onClick={()=>this.clearSearch()} />

                    </div>
                </div>
                <ul className="nav nav-tabs search-tab">
                    <li className="">
                        <a className="active" data-toggle="tab" href="#tag-tab" aria-selected="true" onClick={()=>this.getTags()}>Tags</a>
                    </li>
                    <li className="">
                        <a className="" data-toggle="tab" href="#user-tab" aria-selected="false" onClick={()=>this.getUsers()}>Users</a>
                    </li>
                </ul>
                <div className="tab-content">
                    <div className="tab-pane fade show active" id="tag-tab">
                        {
                            this.state.tags.map((tag, key )=> {
                                return (
                                    <>
                                        <h6 className="mb-3 mt-2" key={key} onClick={()=>this.hashtagSection(tag)}>{tag.name}</h6>
                                    </>
                                )
                            })}
                    </div>
                    <div className="tab-pane fade" id="user-tab">
                        {
                            this.state.users.map((user, key )=> {
                                return (
                                    <div className="user-row" key={key}>
                                        <Link  to={{
                                            pathname: 'u/'+user.username,
                                            state: { user:user }
                                        }}>
                                        <img src={user.image_url ? user.image_url : dummyUser} className="img-fluid rounded-circle userpic" />
                                        </Link>
                                        <div className="user-info">
                                            <h6>{user.name}</h6>
                                            ID: {user.username}
                                        </div>
                                        <button className="btn btn-sm btn-primary btn-round">Follow</button>
                                    </div>
                                )
                            })}
                    </div>
                </div>
                {this.state.loading && <CircularProgress className="loader-spiner" /> }
            </div>
                <Footer/>
            </>
        );
 }
}

export default withRouter(Search);