import React from 'react';
import loadingIcon from "../../Assets/img/loading-icon.gif";

class Loading extends React.Component {

  render() {
  return(
    <>
        <div>
            <img src={loadingIcon} alt="loading" className="loading-class" />
        </div>
    </>
  );
}
}


export default Loading;