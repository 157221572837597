import React, {PureComponent} from 'react';
import './VideoPlayer.css';
import Video from './video/Video';
import {callAPI, userData} from "../../Commen/CommenFunction";
import $ from "jquery";
import Loading from "../Layout/Loading";
import ReactPageScroller from 'react-page-scroller';
import Footer from "../mobile/Footer";
import Swipe from 'react-easy-swipe';
import { withRouter } from "react-router-dom";
import loginImg from '../../Assets/img/loginpageimage.svg';



class VideoPlayer extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            videos:[],
            followVideos:[],
            count:0,
            loading:true,
            paginate:true,
            followPaginate:true,
            page:1,
            followPage:1,
            callApiCount:6,
            followCallApiCount:6,
            index: 1,
            currentPage: 0,
            currentFollowingPage: 0,
        }
        this.fetchVideo = this.fetchVideo.bind(this);
    }

    async componentDidMount() {
        $('body').addClass('theme_bg');
        await this.fetchVideo();
        if (userData){
            await this.fetchFollowVideo();
        } else {
            this.setState({
                loading: false,
            });
        }
        $(".video_section").bind("mousewheel", function() {
            return false;
        });
        /*document.addEventListener('contextmenu', (e) => {
            e.preventDefault();
        });*/
    }

    async fetchVideo(){
        const formVideoData = new FormData()
        formVideoData.set('page', this.state.page)
        try {
            let response = await callAPI('getAllPostList', 'post', formVideoData, 'application/json');
            if (response.status) {
                response.data.data.forEach(el => {
                    this.state.videos.push(el)
                });
                this.setState({
                    videos: this.state.videos,
                    paginate: false
                });
            } else {
                this.setState({
                    found: false,
                    paginate: false
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    async fetchFollowVideo(){
        const formFollowVideoData = new FormData()
        formFollowVideoData.set('page', this.state.page)
        try {
            let followResponse = await callAPI('getFollowVideoList', 'post', formFollowVideoData, 'application/json');
            if (followResponse.status) {
                if (followResponse.status_code == 0) {
                    followResponse.data.data.forEach(el => {
                        this.state.followVideos.push(el)
                    });
                }
                this.setState({
                    followVideos: this.state.followVideos,
                    loading: false,
                    followPaginate: false
                });
            } else {
                this.setState({
                    loading: false,
                    found: false,
                    followPaginate: false
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    handlePageChange = number => {
        this.setState({ currentPage: number });
        /*for (let i = 0 ; i < $('video').length ; i++){
            $('video')[i].muted = false;
            $('.unmute-video').hide();
        }*/
        if(number > 0) {
            $('video')[number].muted = false;
        }
    };

    handleBeforePageChange = number => {
        if (number == this.state.callApiCount) {
            this.setState({
                paginate: true,
                page:this.state.page+1,
                callApiCount:this.state.callApiCount+10,
            });
            this.fetchVideo();
        }
    };

    handleFollowingPageChange = number => {
        this.setState({ currentFollowingPage: number });
        /*for (let i = 0 ; i < $('video').length ; i++){
            $('video')[i].muted = false;
            $('.unmute-video').hide();
        }*/
        if(number > 0) {
            $('video')[number].muted = false;
        }
    };

    handleFollowingBeforePageChange = number => {
        if (number == this.state.followCallApiCount) {
            this.setState({
                followPaginate: true,
                followPage:this.state.followPage+1,
                followCallApiCount:this.state.followCallApiCount+10,
            });
            this.fetchFollowVideo();
        }
    };

    handleChange = (event, value) => {
        this.setState({
            index: value,
        });
    };

    handleChangeIndex = index => {
        this.setState({
            index,
        });
    };

    loginPopup(){
        $('.login-opt-slide').addClass('active');
    }


    async viewVideo(id){
        const formVideoData = new FormData()
        formVideoData.set('post_id', id)
        try {
            let response = await callAPI('viewPost', 'post', formVideoData, 'application/json');
            this.setState({
                viewPost: true,
            });
        } catch (e) {
            console.log(e);
        }
    }

    onSwipeMove = (position, event) => {
        if (position.x < -80    ){
            let video = this.state.videos[this.state.currentPage];
            this.props.history.push({
                pathname: '/'+video.user.username,
                state: { user:video.user, video:video }
            });
        }

        if (position.x > 100){
            console.log('right');
        }
    }

    render() {
        if(this.state.loading) {
            return (
                <Loading />
            );
        } else {
            return (
                <>
                    <ul className="nav nav-tabs follow-tab">
                        <li className="">
                            <a className="" data-toggle="tab" href="#following-tab" aria-selected="true">Following</a>
                        </li>
                        <li className="">
                            <a className="active" data-toggle="tab" href="#trending-tab" aria-selected="false">Trending</a>
                        </li>
                    </ul>
                    <div className="tab-content">
                        <div className="tab-pane fade show" id="following-tab">
                            {userData ? <div className="video_section">
                                    <p className="unmute-video">Tap to Play</p>
                                    <div className="app__videos">
                                        <ReactPageScroller
                                            pageOnChange={this.handleFollowingPageChange}
                                            onBeforePageScroll={this.handleFollowingBeforePageChange}
                                            customPageNumber={this.state.currentFollowingPage}
                                            animationTimer={500}
                                            animationTimerBuffer={50}
                                        >
                                            {
                                                this.state.followVideos.map((followVideo, key) => {
                                                    return (
                                                        <Video
                                                            messages={followVideo.comment_count}
                                                            likes={followVideo.like_count}
                                                            shares={followVideo.share_count}
                                                            description={followVideo.description}
                                                            channel={followVideo.user}
                                                            song={followVideo.get_sound}
                                                            url={followVideo.file_url}
                                                            thumb={followVideo.thumb_url}
                                                            currentPage={0}
                                                            liked={followVideo.liked}
                                                            dataId={key}
                                                            key={key}
                                                        />
                                                    )
                                                })}
                                        </ReactPageScroller>
                                    </div>
                                </div> :
                                <div className="mb-container pag-tp-pt">
                                    <div className="wm-lgn-wrapper">
                                        <div className="login_dialog">
                                            <img src={loginImg} alt="" className="img-fluid mb-4"/>
                                            <h3 className="mb-2">Log In To Continue</h3>
                                            <p>You need to log in to your account to follow.</p>
                                            <a href="#_"
                                               className="theme-btn-wb mt-2 login-btn-click" onClick={()=>this.loginPopup()}> Log In </a>
                                        </div>
                                        <div className="login-opt-slide">
                                            <div className="cls-btn"><i className="fas fa-times"></i></div>
                                            <h3>Login/Sign up</h3>
                                            <p className="mb-4"><a href="#" className="lgn_sign-btn"><i
                                                className="fas fa-mobile-alt"></i> Continue with Phone</a></p>
                                            Read and agree to our <a href="#">Privacy Policy</a> and <a href="#">Terms of
                                            Use</a>
                                        </div>
                                    </div>
                                </div>
                            }
                            <Footer post={this.state.followVideos[this.state.currentFollowingPage]}/>
                        </div>
                        <div className="tab-pane fade show active" id="trending-tab">
                            <Swipe
                                onSwipeMove={this.onSwipeMove}>
                                <div className="video_section">
                                    <p className="unmute-video"></p>
                                    <div className="app__videos">
                                        <ReactPageScroller
                                            pageOnChange={this.handlePageChange}
                                            onBeforePageScroll={this.handleBeforePageChange}
                                            customPageNumber={this.state.currentPage}
                                            animationTimer={500}
                                            animationTimerBuffer={50}
                                        >
                                            {
                                                this.state.videos.map((video, key) => {
                                                    return (

                                                        <Video
                                                            messages={video.comment_count}
                                                            likes={video.like_count}
                                                            view={video.view_count}
                                                            description={video.description}
                                                            channel={video.user}
                                                            url={video.file_url}
                                                            song={video.get_sound}
                                                            thumb={video.thumb_url}
                                                            currentPage={0}
                                                            liked={video.liked}
                                                            dataId={key}
                                                            key={key}
                                                            video={video}
                                                        />
                                                    )
                                                })}
                                        </ReactPageScroller>
                                    </div>
                                </div>
                            </Swipe>
                            <Footer post={this.state.videos[this.state.currentPage]}/>
                        </div>
                    </div>

                </>
            );
        }
    }
}

export default withRouter(VideoPlayer);