import React from 'react';
import swal from "sweetalert";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import dummyImg from '../../../Assets/img/dummy_user.png';
import $ from "jquery";
import dt from "datatables.net";
import {callAdminAPI} from "../../../Commen/CommenFunction";
import Sidebar from "../Layout/Sidebar";


class TournamentDetail extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            event:[]
        }
    }

    async componentDidMount() {
        this.setState({
            loading:true
        });
        const form = new FormData()
        form.set('event_id', this.props.location.state.id)
        const response = await callAdminAPI('admin/eventDetail', 'post',form,'application/json');

        if (response.data.status === true) {
            this.setState({
                event:response.data.data
            });
        }
        this.setState({
            loading:false
        });
        $('.dataTable-data').dataTable( {
            "ordering": false
        } );
    }

    async removeFromEvent(id){
        let event_id = this.state.event.id;
        swal({
            title: "Are you sure?",
            text: "You will not be able to recover this user!",
            icon: "warning",
            buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
            ],
            dangerMode: true,
        }).then(async function(isConfirm) {
            if (isConfirm) {
                const form = new FormData()
                form.set('event_id', event_id)
                form.set('user_id', id)
                const response = await callAdminAPI('admin/removeFromEvent', 'post',form,'application/json');
                if (response.data.status === true) {
                    window.location.reload();
                }
            } else {
                swal("Cancelled", "Your user is safe :)", "error");
            }
        })
    }

    render() {
        if(!this.state.event.current_event_attendants) {
            this.state.event.current_event_attendants = [];
        }
        return(
            <div id="wrapper">
                <Sidebar/>
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Header loading={this.state.loading} />
                        <div className="content-wrapper">
                            <section className="content-header">
                                <div className="container-fluid">
                                    <div className="row mb-2">
                                        <div className="col-sm-6">
                                            <h1>Tournament: {this.state.event.title}</h1>
                                        </div>
                                        <div className="col-sm-6">
                                            <ol className="breadcrumb float-sm-right">
                                                <li className="breadcrumb-item"><a href={'/kurafatadmin'}>Home</a></li>
                                                <li className="breadcrumb-item"><a href={'/kurafatadmin/tournaments'}>Tournaments</a></li>
                                                <li className="breadcrumb-item active">{this.state.event.title}</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section className="content">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h3>Attendant List</h3>
                                                </div>
                                                <div className="card-body">
                                                    <table id="example2" className="table table-bordered table-hover dataTable-data">
                                                        <thead>
                                                        <tr>
                                                            <th>Sr. No.</th>
                                                            <th>Name</th>
                                                            <th>Image</th>
                                                            <th>Type</th>
                                                            <th>Joined Time</th>
                                                            <th>Action</th>
                                                        </tr>
                                                        </thead>

                                                        <tbody>
                                                        {
                                                            this.state.event.current_event_attendants.map((attendant, key )=> {
                                                                return (
                                                                    <tr key={key}>
                                                                        <td>{++key}</td>
                                                                        <td>{attendant.user.name}</td>
                                                                        <td><img height={50} width={50} src={attendant.user.image_url ? attendant.user.image_url : dummyImg } /></td>
                                                                        <td>{attendant.type == "C" ? 'Organizer' : 'Attendant'}</td>
                                                                        <td>{attendant.updated_at}</td>
                                                                        <td>
                                                                            {attendant.type == 'A' ? <button className="btn btn-danger" onClick={()=>this.removeFromEvent(attendant.user_id)}><i className="fa fa-trash"></i></button> : ''}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        );
    }
}

export default TournamentDetail;