import React from 'react';
import loadingIcon from "../../Assets/img/loading-icon.gif";
import Header from "./Header";

class NotFound extends React.Component {

  render() {
  return(
    <>
        <Header />
        <div className="not-found-text">
            <h2>Sorry, this page isn't available.</h2>
            <p>The link you followed may be broken, or the page may have been removed. Go back to <a href={'/'}>Kurafat</a>.</p>
        </div>
    </>
  );
}
}


export default NotFound;