import React from 'react';
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import {callAdminAPI} from "../../../Commen/CommenFunction";
import Sidebar from "../Layout/Sidebar";
import DataTable from 'react-data-table-component';
import swal from "sweetalert";

class Winners extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            winners:[],
            countPerPage:10,
        }
    }

    async componentDidMount() {
        await this.getWinners(1)
    }

    async getWinners(page) {
        try {
            this.setState({
                loading:true
            });
            const form = new FormData()
            form.set('page', page)

            const response = await callAdminAPI('admin/winnerList', 'post', form,'application/json');

            if (response.data.status === true) {
                if (response.data.status_code == 0){
                    this.setState({
                        winners:response.data.data
                    });
                }
            }
            this.setState({
                loading:false
            });
        } catch (e) {
            console.log(e);
        }
    }

    async setPage(page){
        await this.getVideo(page)
    }

    render() {
        const columns = [
            {
                name: 'User Name',
                cell: row => row.user.name,
            },
            {
                name: 'Amount',
                selector: 'amount',
            },
        ];

        return(
            <div id="wrapper">
                <Sidebar/>
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Header loading={this.state.loading} />
                        <div className="content-wrapper">
                            <section className="content-header">
                                <div className="container-fluid">
                                    <div className="row mb-2">
                                        <div className="col-sm-6">
                                            <h1>Winner List</h1>
                                        </div>
                                        <div className="col-sm-6">
                                            <ol className="breadcrumb float-sm-right">
                                                <li className="breadcrumb-item"><a href={'/kurafatadmin'}>Home</a></li>
                                                <li className="breadcrumb-item active">Winners</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section className="content">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">

                                                <div className="card-body">
                                                    <DataTable
                                                        title="Winner List"
                                                        columns={columns}
                                                        data={this.state.winners.data}
                                                        highlightOnHover
                                                        pagination
                                                        paginationServer
                                                        paginationTotalRows={this.state.winners.total}
                                                        paginationPerPage={this.state.countPerPage}
                                                        paginationComponentOptions={{
                                                            noRowsPerPage: true
                                                        }}
                                                        onChangePage={page => this.setPage(page)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        );
    }
}

export default Winners;