import React from 'react';

const Faq =() =>{
  return(
    <>
      <div className="terms-page">
        <h3 className="terms-heading">FAQ</h3>
        <div className="container">
          <ol>
            <li>
              <h5>What is Kurafat all about?</h5>
              <p>Kurafat is a short video app, you can create videos, with multiple options of filters and editing
                features. you can also choose from thousands of sounds and music.</p>
            </li>
            <li>
              <h5>On which devices is the Kurafat app available?</h5>
              <p>You can use the kurafat app on a phone or tablet. The app is accessible on the play store
                currently.</p>
            </li>

            <li>
              <h5>What is shared on Kurafat?</h5>
              <p>Kuarfat is a social short video creating app. Users share their innovative trending videos, challenge
                on Kurafat.</p>
            </li>

            <li>
              <h5>How many videos can we post in a day?</h5>
              <p>unlimited! Yes, you post an unlimited numbers video on Kurafat in a day. Just create and share.</p>
            </li>

            <li>
              <h5>Is Kurafat safe? </h5>
              <p>Kurafat follows all the safety measures to keep you and your content safe. Read our privacy policy to
                know more</p>
            </li>

            <li>
              <h5>Is Kurafat an Indian app? </h5>
              <p>yes, Kurafat is a one hundred percent homegrown Indian app. Ye apne India ka app hai.</p>
            </li>

            <li>
              <h5>What are the different features the Kurafat Camera provides you?</h5>
              <p>kurafat's camera provides you with a bundle of amazing features such as multiple filters, editing
                setting, beauty camera, slow-motion video capturing, and much more. </p></li>

            <li><h5>How can I contact Kurafat?</h5>
              <p>you can always contact get in touch with us through our social media accounts and you also feel free to
                mail us at - </p>
            </li>


            <li>
              <h5>Is Kurafat free? </h5>
              <p>Absolutely, Kurafat is a free app, just go and download it from the play store and enjoy the app.</p>
            </li>
          </ol>
        </div>
      </div>
    </>
  );
};



export default Faq;