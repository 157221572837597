import React from 'react';
import {userData} from "../../Commen/CommenFunction";
import {Home, Search, AddCircleOutline, NotificationsNone, Person} from "@material-ui/icons";
import LoginPopup from "./LoginPopup";
import $ from "jquery";
import CommentSection from "./CommentSection";
import { withRouter } from "react-router-dom";



class Footer extends React.Component {

    constructor(props) {
        super(props);
        this.loginPopup = this.loginPopup.bind(this)
    }

    loginPopup(){
        if (userData){
            this.props.history.push({
                pathname: '/myprofile'
            });
        } else {
            $('.login-opt-slide').addClass('active');
        }
    }

    search(){
        this.props.history.push({
            pathname: '/search',
        });
    }

    upload(){
        this.props.history.push({
            pathname: '/uploadVideo',
        });
    }
    home(){
        this.props.history.push({
            pathname: '/',
        });
    }

    render() {
        let pathUrl = window.location.pathname.split('/')[1];
    return(
        <>
            {this.props.post ? <CommentSection post={this.props.post} /> : ''}
            <LoginPopup />
            <nav className="mobile-bottom-nav">
                <div className={pathUrl == '' ? "item active" : 'item' } onClick={()=>this.home()}>
                    <Home fontSize="large"/>
                </div>
                <div className={pathUrl == 'search' ? "item active" : 'item' } onClick={()=>this.search()}>
                    <Search fontSize="large" />
                </div>
                <div className={pathUrl == 'uploadVideo' ? "item active" : 'item' } onClick={()=>this.upload()}>
                   <AddCircleOutline fontSize='large' />
                </div>
                <div className={pathUrl == 'notification' ? "item active" : 'item' } onClick={()=>this.loginPopup()}>
                    <NotificationsNone fontSize="large" />
                </div>

                <div className={pathUrl == ':username' ? "item active" : 'item' } onClick={()=>this.loginPopup()}>
                    {userData && userData.image_url ? <img className='img-fluid navigation-profile' src={userData.image_url} /> : <Person fontSize="large" /> }
                </div>
            </nav>

        </>
    )
  }
}


export default withRouter(Footer);