import React from 'react';
import dummyImg from '../../../Assets/img/dummy_user.png';
import swal from "sweetalert";
import {userData} from "../../../Commen/CommenFunction";
import Sidebar from "./Sidebar";
import $ from "jquery";

class Header extends React.Component{

    constructor(props) {
        super(props);
        this.state={
            loading:false,
        };
    }

    openCloseSidebar(){
        $('.sidebar-dark-primary').toggleClass('open-close-sidebar')
        $('.wrapper').toggleClass('open-close-sidebar-active')
    }

    logout(){
        localStorage.clear();
        window.location.reload();
    }

    render() {
        this.state.loading = this.props.loading
        return(
            <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                {(() => {
                    if (this.state.loading) {
                        return (
                            <div className="circle_loader_sec processing-loader">
                                <div className="loader">
                                    <div className="m-t-30">
                                        <img src={require("../../../Assets/img/circle-loader.gif")} />
                                    </div>
                                </div>
                            </div>
                        )
                    }
                })()}
                <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                    <i className="fa fa-bars"></i>
                </button>

                <form
                    className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                    <div className="input-group">
                        {/*<input type="text" className="form-control bg-light border-0 small" placeholder="Search for..."
                               aria-label="Search" aria-describedby="basic-addon2"/>
                        <div className="input-group-append">
                            <button className="btn btn-primary" type="button">
                                <i className="fas fa-search fa-sm"></i>
                            </button>
                        </div>*/}
                    </div>
                </form>

                <ul className="navbar-nav ml-auto">
                    <li className="nav-item dropdown no-arrow d-sm-none">
                        <a className="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button"
                           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fas fa-search fa-fw"></i>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
                             aria-labelledby="searchDropdown">
                            <form className="form-inline mr-auto w-100 navbar-search">
                                <div className="input-group">
                                    <input type="text" className="form-control bg-light border-0 small"
                                           placeholder="Search for..." aria-label="Search"
                                           aria-describedby="basic-addon2"/>
                                    <div className="input-group-append">
                                        <button className="btn btn-primary" type="button">
                                            <i className="fas fa-search fa-sm"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </li>

                    <div className="topbar-divider d-none d-sm-block"></div>

                    <li className="nav-item dropdown no-arrow">
                        <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span className="mr-2 d-none d-lg-inline text-gray-600 small">{userData.name}</span>
                            <img className="img-profile rounded-circle"
                                 src={dummyImg} />
                        </a>
                        <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                             aria-labelledby="userDropdown">
                            <div className="dropdown-divider"></div>
                            <a href="_#" onClick={()=>this.logout()} >
                                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                Logout
                            </a>
                        </div>
                    </li>

                </ul>

            </nav>
        );
    }
}

export default Header;