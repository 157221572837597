import React from 'react';
import swal from "sweetalert";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import {callAdminAPI} from "../../../Commen/CommenFunction";
import dummyImg from '../../../Assets/img/dummy_user.png';
import {Link} from "react-router-dom";
import $ from "jquery";
import dt from "datatables.net";
import imageCompression from "browser-image-compression";
import Sidebar from "../Layout/Sidebar";


class CreateEvent extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            events:[],
            title:'',
            description:'',
            start_time:'',
            end_time:'',
            image_url:null,
            image:null,
        }
        this.handleChange = this.handleChange.bind(this)
    }

    async inputChangeHandler(event, key) {
        const value = event.target.value;

        this.setState(state => {
            return {
                ...state,
                [key]: value
            }
        })
    }

   async componentDidMount() {

    }

    async handleChange(event) {
        if(event.target.files['length'] < 1) {
            return false;
        }
        var imageFile = event.target.files[0];

        var mimeType=imageFile['type'];
        if(mimeType.split('/')[0] === 'image'){
            var options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            }
            const compressedFile = await imageCompression(imageFile, options);
            this.setState({
                image_url: URL.createObjectURL(compressedFile),
                image:compressedFile
            })
        } else {
            this.setState({
                image:imageFile
            })
        }

    }

    async submitHandler(event) {
        this.setState({
            loading:true
        });
        event.preventDefault();

        const form = new FormData()
        form.set('title', this.state.title)
        form.set('description', this.state.description)
        form.set('start_time', this.state.start_time)
        form.set('end_time', this.state.end_time)

        if (this.state.image) {
            form.append(
                "image",
                this.state.image,
                this.state.image.name
            );
        }
        try {
            const response = await callAdminAPI('admin/createEvent', 'post', form,'application/json');

            this.setState({
                loading:false
            });
            if (response.data.status == true){
                swal(response.data.message);
                this.props.history.push({
                    pathname: '/kurafatadmin/tournaments'
                })
            } else {
                swal(response.data.message)
            }

        } catch (e) {
            this.setState({
                loading:false
            });
        }
    }

    render() {
        return(
            <div id="wrapper">
                <Sidebar/>
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Header loading={this.state.loading} />
                        <div className="content-wrapper">
                            <section className="content-header">
                                <div className="container-fluid">
                                    <div className="row mb-2">
                                        <div className="col-sm-6">
                                            <h1>Create Event</h1>
                                        </div>
                                        <div className="col-sm-6">
                                            <ol className="breadcrumb float-sm-right">
                                                <li className="breadcrumb-item"><a href={'/kurafatadmin'}>Create Event</a></li>
                                                <li className="breadcrumb-item active">Event</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section className="content">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="card card-primary">
                                                <form className="form" method="post" id="registrationForm" onSubmit={(event) => this.submitHandler(event)}>
                                                    <div className="card-body">
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInput1">Title</label>
                                                            <input type="text" className="form-control" id="exampleInput1"
                                                                   placeholder="Enter Title" value={this.state.title}
                                                                   onChange={(event) => this.inputChangeHandler(event, 'title')} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInput2">Description</label>
                                                            <input type="text" className="form-control"
                                                                   id="exampleInput2" placeholder="Description" value={this.state.description}
                                                                   onChange={(event) => this.inputChangeHandler(event, 'description')}  />
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="exampleInput3">Start Time</label>
                                                                    <input type="datetime-local" className="form-control"
                                                                           id="exampleInput3" placeholder="start time" value={this.state.start_time}
                                                                           onChange={(event) => this.inputChangeHandler(event, 'start_time')}  />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="exampleInput4">End Time</label>
                                                                    <input type="datetime-local" className="form-control"
                                                                           id="exampleInput3" placeholder="End time" value={this.state.end_time}
                                                                           onChange={(event) => this.inputChangeHandler(event, 'end_time')}  />
                                                                </div>
                                                            </div>

                                                            <div className="form-group">
                                                                <div className="row">
                                                                    <div className="col-md-8">
                                                                        <label htmlFor="exampleInputFile">File input</label>
                                                                        <div className="input-group">
                                                                            <div className="custom-file">
                                                                                <input type="file" className="custom-file-input"
                                                                                       id="exampleInputFile" accept=".png, .jpg, .jpeg, .mp4,.webm,.hdv,.flv,.avi,.wmv,.mov" onChange={this.handleChange} />
                                                                                <label className="custom-file-label"
                                                                                       htmlFor="exampleInputFile">Choose
                                                                                    file</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        {this.state.image_url ? <img style={{height:'100%', width:'100%'}} src={this.state.image_url} alt="event" className="pro-img"/> : '' }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="card-footer">
                                                        <button type="submit" className="btn btn-primary">Create</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        );
    }
}

export default CreateEvent;