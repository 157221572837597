import React from 'react';
import '../../Assets/css/mobile_web.css';
import {callAPI, userData} from "../../Commen/CommenFunction";
import MetaTags from 'react-meta-tags';
import dummyImg from '../../Assets/img/dummy_user.png';
import videoIcon from '../../Assets/img/video-icon.svg';
import $ from "jquery";
import { withRouter } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Footer from "./Footer";


class myProfile extends React.Component{

    constructor(props) {
        super(props);
        this.state={
            loading:true,
            user:[],
            post:[],
            page:1,
            scrollHeight:300,
            found:false,
            next_page:true,
            video_url:null
        };
    }

    async componentDidMount() {
        if (!userData){
            window.location.href = '/';
        }
        this.setState({
            user:userData
        });

        $('body').addClass('theme_bg');
        $('.mobile-bottom-nav').addClass('theme_black');

        await this.getPublishedPost();

        window.addEventListener('scroll', this.handleScroll, true);
    }

    handleScroll = async () => {
        let header = $('.cat-info-row');
        if (window.pageYOffset > 80) {
            header.addClass("sticky");
        } else {
            header.removeClass("sticky");
        }
        let scrollDiv = $('.u-gal-scroll');

        scrollDiv.on('scroll',async () => {
            if(scrollDiv.scrollTop() + scrollDiv.innerHeight() >= scrollDiv[0].scrollHeight  && this.state.next_page && this.state.paginate) {
                this.setState({
                    paginate:false,
                });
                await this.getPublishedPost();
            }
        });
    }

    async getPublishedPost() {
        const formData = new FormData()
        formData.set('user_id', userData.id)
        formData.set('page', this.state.page)
        try {
            let response = await callAPI('userPublishedPost', 'post', formData, 'application/json');
            if (response.status){
                if (response.status_code){
                    this.setState({
                        loading:false,
                        found:true,
                        page:this.state.page+1,
                        pageYOffset:this.state.pageYOffset+600,
                        next_page : false,
                        paginate:true
                    });
                } else {
                    response.data.data.forEach(el => {
                        this.state.post.push(el)
                    });
                    let next_page = false
                    if (response.data.next_page_url){
                        next_page = true;
                    }

                    this.setState({
                        post:this.state.post,
                        loading:false,
                        found:true,
                        page:this.state.page+1,
                        pageYOffset:this.state.pageYOffset+600,
                        next_page : next_page,
                        paginate:true
                    });
                }
            } else {
                this.setState({
                    loading:false,
                    found:false
                });
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    playVideo(url) {
        this.setState({
            video_url:url
        });
    }

    logout() {
        localStorage.clear();
        window.location.href = '/';
    }

    render() {

        return (
            <div>
                <MetaTags>
                    <title>{this.state.user.name+" (@"+this.state.user.username+") . kurafat Posts"}</title>
                    <meta name="description" content={this.state.user.follower_count+" Followers, "+this.state.user.following_count+" Following, "+this.state.user.post_count+" Posts - See kurafat Posts from "+this.state.user.name+" (@"+this.state.user.username+")"} />
                    <meta property="og:title" content={this.state.user.name+" (@"+this.state.user.username+") . kurafat Posts"} />
                    <meta property="og:image" content={this.state.user.image_url} />
                </MetaTags>
                <div className="mb-container  vh-100 pag-tp-pt">
                    <div className="three-dot-icon" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa fa-ellipsis-v" aria-hidden="true"></i></div>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a className="dropdown-item three-dot-option" href="#" onClick={()=>this.logout()}>Logout</a>
                    </div>
                    {this.state.video_url &&
                    <div className="profile-video-player">
                        <video src={this.state.video_url} controls />
                    </div>
                    }
                    <div className={this.state.video_url ? "user-profile-video-section u-gal-scroll" : "user-profile-video-section" }>
                        <div className="cat-info-row" id="pro-row-fix">
                            <div className="cat-img">
                                <img src={this.state.user.image_url ? this.state.user.image_url : dummyImg} className="rounded-circle img-fluid" />
                            </div>
                            <div className="cat-info">
                                <div className="user-name-id">
                                    <h3>{this.state.user.name}</h3>
                                    <div className="userid">ID: {this.state.user.username}</div>
                                </div>
                            </div>
                        </div>
                        <ul className="user-flower-list">
                            <li>
                                <span className="fl-num">{this.state.user.following_count}</span>
                                <p>Following</p>
                            </li>
                            <li>
                                <span className="fl-num">{this.state.user.follower_count}</span>
                                <p>Followers</p>
                            </li>
                            <li>
                                <span className="fl-num">{this.state.user.like_count}</span>
                                <p>Likes</p>
                            </li>
                        </ul>
                        <ul className="user-vd-list">
                        {
                            this.state.post.map((post, key )=> {
                                return (
                                    <li className="vd-list-item" key={key} onClick={()=>this.playVideo(post.file_url)}>
                                        <div className="vd-like-count">Like: {post.like_count}</div>
                                            <div className="vd-sign">
                                                <img src={videoIcon} alt="" className="img-fluid" />
                                            </div>
                                        <img src={post.thumb_url} className="img-fluid" alt=""/>
                                    </li>
                                )
                            })}
                    </ul>
                    </div>
                </div>
                {this.state.loading && <CircularProgress className="loader-spiner" /> }
                <Footer />
            </div>
        );
 }
}

export default withRouter(myProfile);