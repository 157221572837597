import React from 'react';
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import {callAdminAPI} from "../../../Commen/CommenFunction";
import dummyImg from '../../../Assets/img/dummy_user.png';
import Sidebar from "../Layout/Sidebar";
import DataTable from 'react-data-table-component';
import swal from "sweetalert";
import $ from "jquery";


class Videos extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            posts:[],
            countPerPage:10,
            type:'',
            url:'',
            post_id:'',
        }
    }

   async componentDidMount() {
        await this.getVideo(1)
    }

    async getVideo(page) {
        try {
            this.setState({
                loading:true
            });
            const form = new FormData()
            form.set('page', page)

            const response = await callAdminAPI('admin/postList', 'post', form,'application/json');

            if (response.data.status === true) {
                if (response.data.status_code == 0){
                    this.setState({
                        posts:response.data.data
                    });
                }
            }
            this.setState({
                loading:false
            });
        } catch (e) {
            console.log(e);
        }
    }

    async setPage(page){
        await this.getVideo(page)
    }

    async blockPost(post_id){
        swal({
            title: "Are you sure?",
            text: "You will get this post in blocked list!",
            icon: "warning",
            buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
            ],
            dangerMode: true,
        }).then(async function(isConfirm) {
            if (isConfirm) {

                const form = new FormData()
                form.set('post_id', post_id)
                const response = await callAdminAPI('admin/changePostStatus', 'post', form,'application/json');

                if (response.data.status === true) {
                    if (response.data.status_code == 0){
                       window.location.reload();
                    }
                }
            } else {
                swal("Cancelled", "Your tournament is safe :)", "error");
            }
        })
    }

    async updatePromotion(post){
        this.setState({
            type:post.promote_type,
            url:post.promote_url,
            post_id: post.id
        });
    }

    async inputChangeHandler(event, key) {
        let value = event.target.value;
        this.setState(state => {
            return {
                ...state,
                [key]: value
            }
        })
    }

    async submitHandler(event) {
        this.setState({
            loading:true
        });
        event.preventDefault();
        const form = new FormData()
        form.set('promotion_type', this.state.type)
        form.set('promotion_url', this.state.url)
        form.set('post_id', this.state.post_id)

        try {
            const response = await callAdminAPI('admin/promotePostAdmin', 'post', form,'application/json');

            this.setState({
                loading:false
            });
            if (response.data.status == true){
                swal(response.data.message);
                window.location.reload();
            } else {
                swal(response.data.message)
            }

        } catch (e) {
            this.setState({
                loading:false
            });
        }
    }

    render() {
        const columns = [
            {
                name: 'User',
                cell: row => <a href={'user_detail/'+row.user.id}>{row.user.name}</a>,
            },
            {
                name: 'Thumb',
                cell: row => <img height={50} width={50} alt={row.description} src={row.thumb_url} />,
            },
            {
                name: 'Caption',
                selector: 'description',
            },
            {
                name: 'Video',
                cell: row => <video controls={true} height={100} width={50} src={row.file_url ? row.file_url : dummyImg } />
            },
            {
                name: 'Promote URL',
                cell: row => <span data-toggle="modal" data-target="#updateVideoPromotion" onClick={()=>this.updatePromotion(row)}>{row.promote_url ? row.promote_url : 'Add Promotion'}</span>
            },
            {
                name: 'Promote Type',
                selector: 'promote_type'
            },
            {
                name: 'Created By',
                selector: 'promote_created_by'
            },
            {
                name: 'Post date',
                selector: 'created_at',
            },
            {
                name: 'Views',
                selector: 'view_count'
            },
            {
                name: 'Shares',
                selector: 'share_count'
            },
            {
                name: 'Action',
                cell: row => <button className='btn btn-danger' onClick={()=>this.blockPost(row.id)}>Block</button>
            },
        ];

        return(
            <div id="wrapper">
                <Sidebar/>
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Header loading={this.state.loading} />
                        <div className="content-wrapper">
                            <section className="content-header">
                                <div className="container-fluid">
                                    <div className="row mb-2">
                                        <div className="col-sm-6">
                                            <h1>Active Video List</h1>
                                        </div>
                                        <div className="col-sm-6">
                                            <ol className="breadcrumb float-sm-right">
                                                <li className="breadcrumb-item"><a href={'/kurafatadmin'}>Home</a></li>
                                                <li className="breadcrumb-item active">Active Videos</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section className="content">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header">

                                                </div>
                                                <div className="card-body">
                                                    <DataTable
                                                        title="Video"
                                                        columns={columns}
                                                        data={this.state.posts.data}
                                                        highlightOnHover
                                                        pagination
                                                        paginationServer
                                                        paginationTotalRows={this.state.posts.total}
                                                        paginationPerPage={this.state.countPerPage}
                                                        paginationComponentOptions={{
                                                            noRowsPerPage: true
                                                        }}
                                                        onChangePage={page => this.setPage(page)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                    <Footer/>
                </div>
                <div className="modal fade modal-radius" id="updateVideoPromotion" tabIndex="-1" role="dialog"
                     aria-labelledby="updateVideoPromotionLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="container cont-mini">
                                    <div className="col-md-11 mx-auto">
                                        <form className="form" method="post" id="registrationForm" onSubmit={(event) => this.submitHandler(event)}>
                                            <div className="card-body">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <label htmlFor="exampleInputFile">Type</label>
                                                            <select name="country_code" onChange={(event) => this.inputChangeHandler(event, 'type')} className="form-control" >
                                                                <option value="">--Option--</option>
                                                                <option selected={this.state.type == 'I'} value="I">Instagram</option>
                                                                <option selected={this.state.type == 'Y'} value="Y">Youtube</option>
                                                                <option selected={this.state.type == 'W'} value="W">Website</option>
                                                                <option selected={this.state.type == 'P'} value="P">Product</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <label htmlFor="exampleInputFile">URL</label>
                                                            <input type="text" className="form-control" id="exampleInput1"
                                                                   placeholder="Enter URL" value={this.state.url}
                                                                   onChange={(event) => this.inputChangeHandler(event, 'url')} />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="card-footer">
                                                <button type="submit" className="btn btn-primary">Create</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Videos;