import React from 'react';
import Swiper from 'react-id-swiper';
// SCSS
import "swiper/swiper.scss";
import dummyUser from '../../../Assets/img/dummy_user.png';


class UserSlider extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sliders:[],
        }
    }

    render() {
        const params = {
            grabCursor: true,
            slidesPerView: 1,
            spaceBetween: 10,
            loop: true,
            breakpoints: {
                1200: {
                    slidesPerView: 6,
                    spaceBetween: 40,
                },
                1024: {
                    slidesPerView: 5,
                    spaceBetween: 40,
                },
                768: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                },
                640: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                320: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
            },
        };

        this.state.sliders = this.props.data;

        return(
            <>
                <Swiper {...params} >
                    {
                        this.state.sliders.map((creator, key )=> {
                            return (
                                <li key={key}><a href={'u/'+creator.username}>
                                    <img src={ creator.image_url ? creator.image_url : dummyUser } alt={creator.name}></img></a>
                                    <p>{creator.name}</p>
                                </li>
                            )
                        })}
                </Swiper>
            </>
        )
    }
}


export default UserSlider;