import React from 'react';
import Swiper from 'react-id-swiper';
// SCSS
import "swiper/swiper.scss";
import $ from "jquery";
import videosignpic from '../../../Assets/img/videosign.png';


class VideoSlider extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      sliders:[],
    }
    this.handleClick = this.handleClick.bind(this);
  }
  componentDidMount() {
    $('.video').click(function(){
      $('video').each((videoIndex, video) => {
        if (videoIndex != this.getAttribute('data-id')){
          video.pause();
        }
      })
      this.paused?this.play():this.pause();
    });
  }

  handleClick(e) {
    this.refs.playPauseVideo.click();
  }

  render() {
    const params = {
      grabCursor: true,
      slidesPerView: 1,
      spaceBetween: 10,
      loop: true,
      breakpoints: {
        1200: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        320: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
      },
    };

    this.state.sliders = this.props.data;

    return(
        <>
          <Swiper {...params}>
            {
              this.state.sliders.map((video, key )=> {
                return (
                    <div className="col-lg-3" key={key}>
                      <div className="how-inner-wrapper">
                        <figure className="how-img">
                          <video ref="playPauseVideo" className="video" data-id={key} autoPlay={false} controls={false} poster={video.thumb_url}>
                            <source src={video.file_url} type="video/mp4" />
                          </video>
                          <div className="video-sign" onClick={this.handleClick}>
                            <img
                              src={videosignpic}
                              alt="videosign" className="img-fluid"/>
                          </div>
                        </figure>
                      </div>
                    </div>
                )
              })}
          </Swiper>
        </>
    )
  }
}


export default VideoSlider;