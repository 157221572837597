import React from 'react';
import headerImg from '../../Assets/img/header.svg';
import ChromeBtn from '../../Assets/img/btn-chrome-store.png';
import '../../Assets/css/Header.scss';
import googleBtn from "../../Assets/img/googlebtn.png";


class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            banner:false,
        }
    }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY > 80) {
      document.querySelector(".navbar").className = "navbar navbar-expand-lg navbar-light fixed-top scroll";
    } else {
      document.querySelector(".navbar").className = "navbar navbar-expand-lg navbar-light fixed-top";
    }
  };
  render() {
      this.state.banner = this.props.banner;
  return(
    <>
     <div className="header header-profile">
       <nav className="navbar navbar-expand-lg navbar-light fixed-top">
           <div className="container">
            <a className="navbar-brand" href={'/'}></a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
             <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
             <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
              <li className="button">
                <a className="button-link weight800" target="_blank" href="https://play.google.com/store/apps/details?id=com.iamkurafati&hl=en_IN&gl=US">Get the App</a>
              </li>
             </ul>
            </div>
           </div>
          </nav>
             <div className={this.state.banner ? 'hero text-center d-block' : 'hero text-center d-none'}>
                 <div className="container">
                     <div className="hero-image">
                         <img src={headerImg} alt="hero-img" className="heroimg  m-auto"/>
                     </div>
                     <div className="hero-content pt-5 pb-5">
                         <p className="text-white">We bring to you an app that entertains, challenges, and brings out the best of you. An app that is a hub all the kurafat, fun and amazing videos challenges and events - <strong>ye apne India ka app hai</strong></p>
                         <div className="hero-image pt-5 pb-5"><a target="_blank" href="https://play.google.com/store/apps/details?id=com.iamkurafati&hl=en_IN&gl=US" className=""><img src={googleBtn} alt="googleBtn"
                                                                                                                                                                                        className="img-fluid google-store-img mx-auto d-block" /></a></div>
                     </div>
                 </div>
             </div>
     </div>
    </>
  );
}
}


export default Header;