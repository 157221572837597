import React from 'react';
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import {callAdminAPI} from "../../../Commen/CommenFunction";
import Sidebar from "../Layout/Sidebar";
import DataTable from 'react-data-table-component';
import swal from "sweetalert";


class Withdraw extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            withdraws:[],
            countPerPage:10,
        }
    }

    async componentDidMount() {
        await this.getWithdraw(1)
    }

    async getWithdraw(page) {
        try {
            this.setState({
                loading:true
            });
            const form = new FormData()
            form.set('page', page)

            const response = await callAdminAPI('admin/withdrawList', 'post', form,'application/json');

            if (response.data.status === true) {
                if (response.data.status_code == 0){
                    this.setState({
                        withdraws:response.data.data
                    });
                }
            }
            this.setState({
                loading:false
            });
        } catch (e) {
            console.log(e);
        }
    }

    async setPage(page){
        await this.getVideo(page)
    }

    async payAmount(id){
        swal({
            title: "Are you sure?",
            icon: "warning",
            buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
            ],
            dangerMode: true,
        }).then(async function(isConfirm) {
            if (isConfirm) {

                const form = new FormData()
                form.set('id', id)
                const response = await callAdminAPI('admin/payAmount', 'post', form,'application/json');

                if (response.data.status === true) {
                    if (response.data.status_code == 0){
                       window.location.reload();
                    }
                }
            }
        })
    }

    render() {
        const columns = [
            {
                name: 'User Name',
                cell: row => row.user.name,
            },
            {
                name: 'Amount',
                selector: 'amount',
            },
            {
                name: 'UPI Name',
                cell: row => row.upi ? row.upi.name : 'UPI not provided',
            },
            {
                name: 'UPI',
                cell: row => row.upi ? row.upi.name : 'UPI not provided',
            },
            {
                name: 'Status',
                cell: row => row.status == 0 ? <button className="btn btn-danger float-right" onClick={() => this.payAmount(row.id)}>Pay</button> : <button className="btn btn-success float-right">Paid</button> ,
            },
        ];

        return(
            <div id="wrapper">
                <Sidebar/>
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Header loading={this.state.loading} />
                        <div className="content-wrapper">
                            <section className="content-header">
                                <div className="container-fluid">
                                    <div className="row mb-2">
                                        <div className="col-sm-6">
                                            <h1>Withdraw List</h1>
                                        </div>
                                        <div className="col-sm-6">
                                            <ol className="breadcrumb float-sm-right">
                                                <li className="breadcrumb-item"><a href={'/kurafatadmin'}>Home</a></li>
                                                <li className="breadcrumb-item active">Withdraw</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section className="content">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">

                                                <div className="card-body">
                                                    <DataTable
                                                        title="Withdraw List"
                                                        columns={columns}
                                                        data={this.state.withdraws.data}
                                                        highlightOnHover
                                                        pagination
                                                        paginationServer
                                                        paginationTotalRows={this.state.withdraws.total}
                                                        paginationPerPage={this.state.countPerPage}
                                                        paginationComponentOptions={{
                                                            noRowsPerPage: true
                                                        }}
                                                        onChangePage={page => this.setPage(page)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                    <Footer/>
                </div>
                <div className="modal fade modal-radius" id="createPromotional" tabIndex="-1" role="dialog"
                     aria-labelledby="downloadModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="container cont-mini">
                                    <div className="col-md-11 mx-auto">
                                        <form className="form" method="post" id="registrationForm" onSubmit={(event) => this.submitHandler(event)}>
                                            <div className="card-body">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-8">
                                                            <label htmlFor="exampleInputFile">File input</label>
                                                            <div className="input-group">
                                                                <div className="custom-file">
                                                                    <input type="file" className="custom-file-input"
                                                                           id="exampleInputFile" accept=".png, .jpg, .jpeg, .mp4,.webm,.hdv,.flv,.avi,.wmv,.mov" onChange={this.handleChange} />
                                                                    <label className="custom-file-label"
                                                                           htmlFor="exampleInputFile">Choose
                                                                        file</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            {this.state.image_url ? <img style={{height:'100%', width:'100%'}} src={this.state.image_url} alt="event" className="pro-img"/> : '' }
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="card-footer">
                                                <button type="submit" className="btn btn-primary">Create</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Withdraw;