import React from 'react';
import '../../Assets/css/mobile_web.css';
import { callAPI} from "../../Commen/CommenFunction";
import dummyImg from '../../Assets/img/dummy_user.png';
import videoIcon from '../../Assets/img/video-icon.svg';
import $ from "jquery";
import {Link} from "react-router-dom";
import compressLoader from "../../Assets/img/compress_loader.gif";
import imageCompression from 'browser-image-compression';


class Register extends React.Component{

    constructor(props) {
        super(props);
        this.state={
            name: '',
            mobile:'',
            about:'',
            loading:false,
            image:'',
            compressLoader:false,
            provider_id:'',
            type:''
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    async inputChangeHandler(event, key) {
        const value = event.target.value;

        this.setState(state => {
            return {
                ...state,
                [key]: value
            }
        })
    }

    async componentDidMount() {
        if(this.props.history.location && this.props.history.location.state && this.props.history.location.state.provider_id){
            this.setState({
                provider_id:this.props.history.location.state.provider_id,
                type:this.props.history.location.state.type,
            });
        } else {
            window.location.href = '/';
        }


        $('body').addClass('theme_bg');
    }

    async handleChange(event) {
        this.setState({
            compressLoader:true
        })
        if(event.target.files['length'] < 1) {
            return false;
        }
        var imageFile = event.target.files[0];
        var options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        }
        const compressedFile = await imageCompression(imageFile, options);
        this.setState({
            image_url: URL.createObjectURL(compressedFile),
            image:compressedFile,
            compressLoader:false
        })

    }

    handleClick(e) {
        this.refs.fileUploader.click();
    }

    async submitHandler(event) {
        this.setState({
            loading:true
        });
        event.preventDefault();

        const form = new FormData()
        form.set('name', this.state.name)
        form.set('email', this.state.email)
        form.set('provider_id', this.state.provider_id)
        form.set('login_type', this.state.type)
        form.set('device_type', 'W')
        form.set('device_token', localStorage.getItem('fcm-token') ? localStorage.getItem('fcm-token') : '')

        if (this.state.image) {
            form.append(
                "image",
                this.state.image,
                this.state.image.name
            );
        }
        try {
            const response = await callAPI('socialRegister', 'post', form,'application/json');

            if (response.status === true) {
                localStorage.setItem("user", JSON.stringify(response.data));
                window.location.href = '/';
            } else {
                alert(response.message);
            }
            this.setState({
                loading:false
            });
        } catch (e) {
            console.log(e);
            this.setState({
                loading:false
            });
        }
    }

    render() {
        return (
            <div className="mb-container pag-tp-pt">
                <div className="back_arrow"><i className="fa fa-arrow-left" aria-hidden="true"></i></div>
                <center class="mt-3">
                    <div className="user-pro-pic-holder">
                        <img src={compressLoader} className={this.state.compressLoader ? "pro-img-loader" : "pro-img-loader loader-hide"}/>
                        <img src={this.state.image_url ? this.state.image_url : dummyImg} alt="user" onClick={this.handleClick} className="rounded-circle" />
                            <div className="user-pro-pic-edit"><i className="fas fa-pencil-alt" onClick={this.handleClick}></i>
                                <input type="file" id="file" ref="fileUploader" name="profile_pic" className="icon-edit_icn" accept="image/png, image/jpeg" onChange={this.handleChange} style={{display:"none"}}/>
                            </div>
                    </div>
                </center>
                <form className="form" method="post" id="registrationForm" onSubmit={(event) => this.submitHandler(event)}>
                    <div className="form-group trans-input mt-5">
                        <label htmlFor="exampleInputEmail1">Your Name</label>
                        <input type="text" className="form-control" id="InputName"
                               value={this.state.name}
                               onChange={(event) => this.inputChangeHandler(event, 'name')}
                               name="name"
                               placeholder="Enter Name"/>
                    </div>
                    <center class="mt-5"><button type='submit' className="sm-btn float-right mt-5 next-btn"> NEXT </button>
                    </center>
                </form>
            </div>
        );
 }
}

export default Register;