import firebase from "firebase";

export const initializedFirebaseApp = firebase.initializeApp({
  // Project Settings => Add Firebase to your web app
  apiKey: "AIzaSyCRZDS-0MvC4_HOafywn077CF4lb81VxlA",
  authDomain: "kurafat-9a99a.firebaseapp.com",
  databaseURL: "https://kurafat-9a99a.firebaseio.com",
  projectId: "kurafat-9a99a",
  storageBucket: "kurafat-9a99a.appspot.com",
  messagingSenderId: "147828032204",
  appId: "1:147828032204:web:367b481aada70ce66ef869",
  measurementId: "G-X34TVZZ0X8"
});

let messaging = null;
if(firebase.messaging.isSupported()) {
  messaging = initializedFirebaseApp.messaging();
}

export { messaging };
