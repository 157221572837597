import React from 'react';

const PrivacyPolicy =() =>{
  return(
    <>

      <div className="terms-page">
        <h3 className="terms-heading"><strong>Privacy policy </strong></h3>
        <div className="container">
          <p> Welcome to Kurafat, an app that is a hub of short videos, challenges, and events to create and join. If you are using the Kurafat app this privacy policy will be applied to you - </p>

          <h4>Information collected by our site/App</h4>

          <p>We collect information and data when the user browse through our site/app, such as- </p>
          <ul>
            <li> The device you use – We collect the IP address of the device you use to connect to the internet and the type of operating system used so that we can provide you with a better experience.</li>
            <li>  The information which is given by you voluntarily – The information given by you voluntarily by accepting our cookie or responding to our emails, etc.</li>
            <li> Data that you give in conversation with us – The data that provide us within the conversation with us, like any details regarding payment.</li>
          </ul>

          <h4>What Data Do We Collect?</h4>

          <p>Data collected from the site/app will be done automatically (as specified in the section on the use of cookies), whereas other data will only be collected if you willingly submit it or decide on it for use. </p>

          <p>Information you provide -</p>
          <ul>
            <li>Registration Information, such as age, username, and password, language, and email or phone number</li>
            <li> Profile information, such as name, social media account information, and profile image</li>
            <li> User-generated content, photographs, videos, and virtual item videos that you choose to upload or broadcast on the Platform (“User Content”)</li>
            <li> Your opt-in choices and communication preferences</li>
            <li> Information to verify an account </li>
            <li> Information in correspondence you send to us</li>
            <li> Information you share through surveys or your participation in challenges, events, or competitions such as your gender, age, likeness, and preferences</li>
          </ul>
          <h4>How do we use the information we collect from you?</h4>

          <p>Kurafat, do not sell, lease or rent your personal information, or disclose it to any third parties unless there’s any valid legal reason. Or there has been any obligation in the contract. We use the data that we collect from your side to provide you our best services. Furthermore, we may use the information for one or more of the below-mentioned purposes:</p>
          <ul>
            <li>To provide you with a glimpse of information that you may have requested from us related to our products or services. With your permission and/or where permitted by law, We may also use your data for marketing purposes which may involve contacting you by email AND/OR telephone AND/OR text message AND/OR post with information, news and offers on Our products AND/OR services.</li>
            <li>To help you by providing the information related to our other products and services that you may be of interest in. But such the following information will only be sent to you if you have been permitted to receive the information.</li>
            <li>To notify you about our new or additional service, if any. And, to inform you of the changes to our website, services, and products.</li>
          </ul>

          <p><strong>Note -</strong> We will not, however, send you any uncalled marketing or spam and will take all well thought out steps to ensure that we fully protect your rights and abide by Our obligations under the Data Protection Act 1998 and the Privacy and Electronic Communications (EC Directive) Regulations 2003, as amended in 2004, 2011 and 2015.</p>

          <h4>Use of cookie </h4>

          <p>Cookies may be used to personalize your website's/ mobile application experience, Cookies provide information regarding the computer or device used by a visitor. We may also gather information about general Internet use. We do not use cookies to track or collect personal identification information from website users.</p>
          <p>If you don't want us to save your data, you can always change the settings on your device to decline the cookies. </p>


          <h4>Securing your personal information/ information security</h4>

          <p>By using or by registering your profile with our Platform and/or by using Services offered in connection with our Platform, You are consenting to the use, collection, transfer, storage, disclosure and other uses of Your information in the manner set out in this Privacy policy.</p>

          <p>All personal data is stored securely in accordance with the principles of the General Data Protection Regulation (GDPR) as approved by the EU Parliament on 14 April 2016 and enforced from 25 May 2018.</p>
          <p>When you provide us with your personal information, We work hard to protect every bit of it, at kurafat we use every possible security measures to protect the loss, misuse, and/or alteration of any information under our control. </p>

          <p>But Unfortunately, the information you are sending via the Internet is not fully defensible at all points and it can be thwarted skillfully. We cannot ensure that the data security which you send us electronically, will entirely be safe, because sending such information is completely at your own risk. But what we can assure you, is that we will do everything on our end to keep your valuable information safe as much as we can.</p>

          <h4>Protection of personal data </h4>

          <p>The transmission of all personal data is secured with the use of SSL mechanisms (Secure Sockets Layer) to ensure that they are not intercepted by a third party during transmission.</p>


          <h4>Third-Party Content</h4>

          <p>Periodically, we may include links to the third parties on the website,  we do not uphold or authorize our site’s policy towards visitor privacy, however, we are not accountable for the privacy statements, practices, or the contents of such third-party websites. Make sure you do evaluate the third-party privacy policy before delivering the visitors any personal data.</p>

          <h4>Your Rights</h4>

          <p>You may relinquish a request to access or delete the information we have collected about you by sending your request to us at the email or physical address provided in the Contact section at the bottom of this policy. We will respond to your request consistent with favorable law and subject to proper confirmation. And we do not differentiate on any basis on the exercise of any privacy rights that you might have.</p>

          <h4>Amendments and changes to this privacy policy</h4>

          <p>Please note that this Privacy Policy may change or can be amended from time to time and the same will be communicated in writing, Any time, we can revamp this privacy policy. And as soon as we do any amendments, we will precisely modify and update it on the page.
            We contemplate that you acknowledge and agree to our policies and that you exclusively must check and review the privacy policy oftentimes and periodically and be attentive to any updates and modifications made.</p>

          <h4>Contact</h4>

          <p>Problems, comments, questions, and requests regarding this policy should be addressed to:</p>
          <p><strong>Mailing Address: </strong>iamkurafati@kurafat.com  </p>
        </div>
      </div>
    </>
  );
};



export default PrivacyPolicy;