import React, { useState} from 'react'
import  FavoriteIcon from "@material-ui/icons/Favorite"
import MessageIcon from "@material-ui/icons/Message"
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder"
import VisibilityIcon from '@material-ui/icons/Visibility';
import "./VideoSidebar.css"
import googleBtn from "../../../Assets/img/googlebtn.png";
import { Link } from 'react-router-dom';
import {callAPI, userData} from "../../../Commen/CommenFunction";
import $ from "jquery";
import CommentSection from "../../mobile/CommentSection";

function VideoSidebar({messages, view, likes, user, thumb, likedVideo,video}) {
    const [liked, setLiked ] = useState(likedVideo);
    const [childLiked, setChildLiked ] = useState(likedVideo);

    if (liked != likedVideo){
        setLiked(likedVideo)
        setChildLiked(likedVideo)
    }

    const likePost = async () => {
        const formVideoData = new FormData()
        formVideoData.set('post_id', video.id)
        try {
            let response = await callAPI('likePost', 'post', formVideoData, 'application/json');
        } catch (e) {
            console.log(e);
        }
        setChildLiked(true)
    }
    const unlikePost = async () => {
        const formVideoData = new FormData()
        formVideoData.set('post_id', video.id)
        try {
            let response = await callAPI('unlikePost', 'post', formVideoData, 'application/json');
        } catch (e) {
            console.log(e);
        }
        setChildLiked(false)
    }
    const commentPopup = () => {
        $('.comment-opt-slide').addClass('active');
        $('#root').append('<div class="backdrop"></div>');
    }

    return (
        <>
        <div className="videoSidebar">
            <div className="videoSidebar__button">
                <Link  to={{
                    pathname: 'u/'+user.username,
                    state: { user:user }
                }}><img src={user.image_url ? user.image_url : thumb} /></Link>

            </div>
            <div className="videoSidebar__button">
                {liked && childLiked ?
                    (<FavoriteIcon fontSize="large"  style={{fill: 'red'}} onClick={unlikePost}/>) : (<FavoriteBorderIcon fontSize="large" onClick={likePost}/>)

                }
                <p>{liked && childLiked ? likes+1 : likes}</p>
            </div>
            <div className="videoSidebar__button comment_box_btn" onClick={commentPopup}>
                <MessageIcon fontSize="large"/>
                <p>{messages}</p>
            </div>
            <div className="videoSidebar__button">
                <VisibilityIcon fontSize="large"/>
                <p>{view}</p>
            </div>

            <div className="modal fade modal-radius" id="downloadModel" tabIndex="-1" role="dialog"
                 aria-labelledby="downloadModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="container cont-mini">
                                <div className="col-md-11 mx-auto">
                                    <div className="download-sect">
                                        <h3>Download App</h3>
                                        <p className="mb-4 down-txt">You may download app from google play store or click below link
                                            to download</p>
                                        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.iamkurafati&hl=en_IN&gl=US"><img src={googleBtn} alt="googleBtn"
                                                                                                                                                    className="img-fluid google-store-img mx-auto d-block" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default VideoSidebar
