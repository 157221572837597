import React from 'react';
import {callAPI, fbData, googleData, mainURL} from "../../Commen/CommenFunction";
import swal from "sweetalert";
import $ from "jquery";


class Login extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            mobile:'',
            otp:'',
            otpStatus:false,
            resOtp:'',
            user:[],
            registerStatus:false,
            countries:[],
            country_code:'+91',
            minutes: 1,
            seconds: 0,
        }
    }

    async componentDidMount() {
        $('body').addClass('theme_bg');
        try {
            const response = await callAPI('getCountries', 'post', 'application/json');
            if (response.status === true) {
                this.setState({
                    countries:response.data,
                });
            } else{
                alert(response.message);
            }
        }
        catch (e) {
            console.log(e);
        }

        this.myInterval = setInterval(() => {
            const { seconds, minutes } = this.state

            if (seconds > 0) {
                this.setState(({ seconds }) => ({
                    seconds: seconds - 1
                }))
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    //clearInterval(this.myInterval)
                } else {
                    this.setState(({ minutes }) => ({
                        minutes: minutes - 1,
                        seconds: 59
                    }))
                }
            }
        }, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.myInterval)
    }


    async inputChangeHandler(event, key) {
        const value = event.target.value;

        this.setState(state => {
            return {
                ...state,
                [key]: value
            }
        })
    }

    async changeNumber(){
        this.setState({
            otpStatus:false,
        });
    }

    async verifyOtp(){
        if (this.state.otp == this.state.resOtp){
            if (this.state.registerStatus){
                localStorage.setItem("user", JSON.stringify(this.state.user));
                window.location.href = '/';
            } else {
                this.props.history.push({
                    pathname: '/register',
                    state: {mobile: this.state.mobile}
                });
            }
        } else {
            alert('Wrong Otp');
        }
    }

    async submitHandler(event) {
        event.preventDefault();
        await this.submitLoginForm();
    }

    async submitLoginForm(){
        const form = new FormData()
        form.set('country_code', this.state.country_code)
        form.set('mobile', this.state.mobile)
        form.set('device_type', 'W')
        form.set('device_token', localStorage.getItem('fcm-token') ? localStorage.getItem('fcm-token') : '')
        this.setState({
            loading:true
        });
        try {
            const response = await callAPI('login', 'post', form,'application/json');

            if (response.status === true) {
                this.setState({
                    otpStatus:true,
                    resOtp:response.data.otp,
                    loading:false,
                    minutes: 1,
                    seconds: 0,
                });
                if (response.status_code == 1){
                    this.setState({
                        registerStatus : false
                    });
                } else {
                    this.setState({
                        registerStatus : true,
                        user:response.data
                    });
                }
            } else {
                swal(response.message)
            }

            this.setState({
                loading:false
            });
        } catch (e) {
            console.log(e);
        }
    }


  render() {

  return(
    <>
        <div className="mb-container pag-tp-pt">
            <div className="back_arrow"><a href={'/'}><i className="fa fa-arrow-left" aria-hidden="true"></i></a></div>
            <h3 className="def-head-fs mt-3">{this.state.otpStatus ? 'Enter the 4-digit verification code' : 'Enter Your Mobile No'}</h3>
            <br/>
            <form className="form" method="post" id="registrationForm" onSubmit={(event) => this.submitHandler(event)}>
                <div className="d-flex country-ph-row">
                    <div className="country-code-col">
                        <select name="country_code" onChange={(event) => this.inputChangeHandler(event, 'country_code')} className="" id="sel3" required={true}>
                            <option value="">-- Option --</option>+
                            { this.state.countries.map((country, key)=> (
                                <option key={key} value={country.code} selected={country.code == +91}>{country.code} ({country.sort_name})</option>
                            ))}
                        </select>
                    </div>

                    <div className="phone-no-col">
                        <input type="number" className="" value={this.state.mobile}
                               onChange={(event) => this.inputChangeHandler(event, 'mobile')}
                               name="mobile" id="Phoneno"
                               placeholder="Phone No"  required="required" readOnly={this.state.otpStatus ? true : false} />
                    </div>
                </div>

                {(() => {
                    if (this.state.otpStatus == false){
                        return(
                            <>
                                <p className="mv-sm-txt mt-4 px-4 text-center">Tap Next to verify your account. An SMS will be sent to
                                    verify your number. Message and data rates may apply.</p>
                                <center><button className="theme-btn-wb mt-2 btn"> NEXT </button></center>
                            </>
                        )
                    }
                })()}

            </form>

            {(() => {
                if (this.state.otpStatus){
                    return(
                        <div>
                            <div className="sent-code-row">
                                <div>We have sent you 4 digit code on your phone number. <a href="#" onClick={()=>this.changeNumber()}>Change Number</a></div>
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control mb-0" value={this.state.otp}
                                       onChange={(event) => this.inputChangeHandler(event, 'otp')}
                                       name="otp" id="OTP"
                                       placeholder="Enter OTP" required="required" />
                            </div>
                            <div className="opt-col">
                                { this.state.minutes === 0 && this.state.seconds === 0
                                    ? <button className="btn btn-primary btn-sm" onClick={()=>this.submitLoginForm()}>Resend OTP</button>
                                    : <div>Resend OTP: {this.state.minutes}:{this.state.seconds < 10 ? `0${this.state.seconds}` : this.state.seconds}</div>
                                }
                            </div>
                            <div className="kurafat-btn">
                                    <a href="javascript:void(0);" className="theme-btn-wb mt-2" onClick={()=>this.verifyOtp()}>Verify</a>
                            </div>
                        </div>
                    )
                }
            })()}

        </div>
    </>
  );
 }
}

export default Login;