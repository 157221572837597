import React from 'react';
import Footer from "./Footer";
import $ from "jquery";



class uploadVideo extends React.Component{

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        $('body').addClass('theme_bg');

        if ("vibrate" in navigator) {
            // vibration API supported
            navigator.vibrate([2000, 500, 2000]);
        }
    }

    render() {
        return (
            <div>
                <div className="uploader">
                    <h1>Upload</h1>
                    <p>{localStorage.getItem('fcm-token')}</p>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default uploadVideo;