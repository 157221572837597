import React from 'react';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import '../../Assets/css/button.scss';
import '../../Assets/css/Home.scss';
import HowImage1 from '../../Assets/img/how-download-kurafat.png';
import HowImage2 from '../../Assets/img/how-find-offers.png';
import HowImage3 from '../../Assets/img/how-cash-back.png';
import Categoryslider from '../Layout/Slider/Categoryslider';
import Image from '../../Assets/img/prefooter-phone.png';
import {callAPI} from "../../Commen/CommenFunction";
import googleBtn from "../../Assets/img/googlebtn.png";
import Loading from "../Layout/Loading";
import ChallengeSlider from "../Layout/Slider/ChallengeSlider";
import UserSlider from "../Layout/Slider/UserSlider";
import VideoSlider from "../Layout/Slider/VideoSlider";

class Website extends React.Component{

    constructor(props) {
        super(props);
        this.state={
            loading:true,
            popularCreator:[],
            challenges:[],
            videos:[],
            found:false
        };
    }

    async componentDidMount() {
        try {
            let response = await callAPI('popularCreator', 'post', null, 'application/json');
            if (response.status){
                this.setState({
                    popularCreator:response.data.data,
                    found:true
                });
            } else {
                this.setState({
                    found:false
                });
            }
        }
        catch (e) {
            console.log(e);
        }

        const formVideoData = new FormData()
        formVideoData.set('search_type', 'V')
        try {
            let response = await callAPI('search', 'post', formVideoData, 'application/json');
            if (response.status){
                this.setState({
                    videos:response.data.data,
                    loading:false,
                    found:true
                });
            } else {
                this.setState({
                    loading:false,
                    found:false
                });
            }
        }
        catch (e) {
            console.log(e);
        }

        const formData = new FormData()
        formData.set('search_type', 'C')
        try {
            let response = await callAPI('search', 'post', formData, 'application/json');
            if (response.status){
                this.setState({
                    challenges:response.data.data,
                    loading:false,
                    found:true
                });
            } else {
                this.setState({
                    loading:false,
                    found:false
                });
            }
        }
        catch (e) {
            console.log(e);
        }
    }

  render() {
      if(this.state.loading) {
          return (
              <Loading />
          );
      } else {
          return(
              <>
                  <Header banner={true} />
                  <section className="how-it-works">
                      <div className="container">
                          <div className="row">
                              <div className="col-10 mx-auto">
                                  <div className="how-inner text-center">
                                      <h2 className="heading-title heading-title-md b-m"> How does kurafat works?</h2>
                                      <div className="how-inner-content">
                                          <div className="row">
                                              <div className="col-lg-3">
                                                  <div className="how-inner-wrapper">
                                                      <figure className="how-img">
                                                          <img width="173" height="210" src={HowImage1} alt="PhoneApp"></img>
                                                      </figure>
                                                      <div className="how-img-content">
                                                          <h3 className="heading-title heading-title-sm">  Download kurafat</h3>
                                                          <p className="description">
                                                              Download the kurafat app for free on the Play store or browse it on the web.
                                                          </p>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="col-lg-3">
                                                  <div className="how-inner-wrapper">
                                                      <figure className="how-img">
                                                          <img width="176" height="204" src={HowImage2} alt="PhoneApp"></img>
                                                      </figure>
                                                      <div className="how-img-content">
                                                          <h3 className="heading-title heading-title-sm">Create short videos</h3>
                                                          <p className="description">Create short videos, using the unlimited playlist and video content available on kurafat.
                                                          </p>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="col-lg-3">
                                                  <div className="how-inner-wrapper">
                                                      <figure className="how-img">
                                                          <img width="176" height="204" src={HowImage3} alt="PhoneApp"></img>
                                                      </figure>
                                                      <div className="how-img-content">
                                                          <h3 className="heading-title heading-title-sm">Join or create challenges</h3>
                                                          <p className="description">Create or join different challenges and invite your friends and family to participate too.
                                                          </p>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="col-lg-3">
                                                  <div className="how-inner-wrapper">
                                                      <figure className="how-img">
                                                          <img width="193" height="210" src={HowImage1} alt="PhoneApp"></img>
                                                      </figure>
                                                      <div className="how-img-content">
                                                          <h3 className="heading-title heading-title-sm">Chat
                                                          </h3>
                                                          <p className="description">Chat your way through. discuss, plan, organize challenges, events, or make videos with others.
                                                          </p>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </section>
                  <section className="cash-back-brand text-center">
                      <div className="container">
                          <div className="row">
                              <div className="col-10 mx-auto">
                                  <div className="cash-back-text">
                                      <h2 className="heading-title heading-title-lg b-m-sm">Popular Creator.</h2>
                                  </div>
                                  <div className="cash-brand-images">
                                      <ul>
                                          <ul>
                                              <UserSlider data={this.state.popularCreator} />
                                          </ul>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </section>
                  <section className="how-it-works">
                      <div className="container">
                          <div className="row">
                              <div className="col-10 mx-auto">
                                  <div className="how-inner text-center">
                                      <h2 className="heading-title heading-title-md b-m"> Trending Video On Kurafat</h2>
                                      <div className="how-inner-content">
                                          <div className="row">
                                              <VideoSlider data={this.state.videos} />
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </section>
                  <section className="cash-back-brand text-center">
                      <div className="container">
                          <div className="row">
                              <div className="col-10 mx-auto">
                                  <div className="cash-back-text">
                                      <h2 className="heading-title heading-title-lg b-m-sm">Trending Challenge On Kurafat.</h2>
                                  </div>
                                  <div className="cash-brand-images">
                                      <ul>
                                          <ChallengeSlider data={this.state.challenges} />
                                      </ul>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </section>
                  <section className="sign-up">
                      <div className="container">
                          <div className="row">
                              <div className="col-10 mx-auto">
                                  <div className="row">
                                      <div className="col-lg-6">
                                          <div className="signup-content-text">
                                              <h2 className="heading-title heading-title-md text-white mb-4">Sign up and refer codes </h2>
                                              <p className="text-white mb-5"><strong>Register your account</strong> - To be a kurafati you have to register your account on kurafat and be a part of our kurafati community.</p>
                                              <div className="sign-up-btn button">
                                                  <a href="https://play.google.com/store/apps/details?id=com.iamkurafati&hl=en_IN&gl=US" className="button-link btn-md">Sign up</a>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-lg-6">
                                          <div className="signup-dollar-img"><a target="_blank" href="https://play.google.com/store/apps/details?id=com.iamkurafati&hl=en_IN&gl=US" className=""><img src={ googleBtn } alt="dollar" /></a></div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </section>
                  <section className="partner">
                      <div className="container">
                          <div className="row">
                              <div className="col-10 mx-auto">
                                  <div className="category-slider-heading text-center">
                                      <h1 className="heading-title heading-title-lg b-m-sm"></h1>
                                      <h1 className="heading-title heading-title-lg b-m-sm">Something for everyone</h1>
                                  </div>
                                  <div className="partner-slider">
                                      <Categoryslider />
                                  </div>
                              </div>
                          </div>
                      </div>
                  </section>
                  <section className="join-us">
                      <div className="container">
                          <div className="row">
                              <div className="col-10 mx-auto">
                                  <div className="row">
                                      <div className="col-lg-6">
                                          <div className="join-sign">
                                              <h1 className="heading-title heading-title-lg b-m-sm">Download the App Now.</h1>
                                              <p>You may download app from google play store or click below link to download</p>
                                              <div className="button pt-3"><a target="_blank" href="https://play.google.com/store/apps/details?id=com.iamkurafati&hl=en_IN&gl=US" className=""><img src={googleBtn} alt="googleBtn"
                                                                                                                                                                                                    className="img-fluid google-store-img mx-auto d-block" /></a></div>
                                          </div>
                                      </div>
                                      <div className="col-lg-6">
                                          <div className="elementory-image">
                                              <img src={ Image } alt="img"/>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </section>
                  <Footer/>
              </>
          );

      }
 }
}

export default Website;