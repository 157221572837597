import React from 'react';
import Swiper from 'react-id-swiper';
// SCSS
import "swiper/swiper.scss";
import dummyUser from '../../../Assets/img/dummy_user.png';


class ChallengeSlider extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      sliders:[],
    }
  }

  render() {
    const params = {
      grabCursor: true,
      slidesPerView: 1,
      spaceBetween: 10,
      loop: true,
      breakpoints: {
        1200: {
          slidesPerView: 6,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 40,
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
        640: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        320: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
      },
    };

    this.state.sliders = this.props.data;

    return(
        <>
          <Swiper {...params}>
            {
              this.state.sliders.map((challenge, key )=> {
                return (
                    <li key={key}><a href="#_">
                      <img src={ challenge.file_url ? challenge.file_url : dummyUser } alt={challenge.title}></img></a>
                      <p>{challenge.title}</p>
                    </li>
                )
              })}
          </Swiper>
        </>
    )
  }
}


export default ChallengeSlider;