import React from 'react';
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import {callAdminAPI} from "../../../Commen/CommenFunction";
import dummyImg from '../../../Assets/img/dummy_user.png';
import Sidebar from "../Layout/Sidebar";
import DataTable from 'react-data-table-component';
import swal from "sweetalert";


class BlockedVideos extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            posts:[],
            countPerPage:10
        }
    }

    async componentDidMount() {
        await this.getVideo(1)
    }

    async getVideo(page) {
        try {
            this.setState({
                loading:true
            });
            const form = new FormData()
            form.set('page', page)

            const response = await callAdminAPI('admin/blockPostList', 'post', form,'application/json');

            if (response.data.status === true) {
                if (response.data.status_code == 0){
                    this.setState({
                        posts:response.data.data
                    });
                }
            }
            this.setState({
                loading:false
            });
        } catch (e) {
            console.log(e);
        }
    }

    async setPage(page){
        await this.getVideo(page)
    }

    async blockPost(post_id){
        swal({
            title: "Are you sure?",
            text: "You will get this post in blocked list!",
            icon: "warning",
            buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
            ],
            dangerMode: true,
        }).then(async function(isConfirm) {
            if (isConfirm) {
                const form = new FormData()
                form.set('post_id', post_id)

                const response = await callAdminAPI('admin/changePostStatus', 'post', form,'application/json');

                if (response.data.status === true) {
                    if (response.data.status_code == 0){
                        window.location.reload();
                    }
                }
            } else {
                swal("Cancelled", "Your tournament is safe :)", "error");
            }
        })
    }

    render() {
        const columns = [
            {
                name: 'Thumb',
                cell: row => <img height={50} width={50} alt={row.description} src={row.thumb_url} />,
            },
            {
                name: 'Caption',
                selector: 'description',
            },
            {
                name: 'Video',
                cell: row => <video controls={true} height={100} width={50} src={row.file_url ? row.file_url : dummyImg } />
            },
            {
                name: 'Post date',
                selector: 'created_at',
            },
            {
                name: 'Action',
                cell: row => <button className='btn btn-danger' onClick={()=>this.blockPost(row.id)}>Unblock</button>
            }
        ];

        return(
            <div id="wrapper">
                <Sidebar/>
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Header loading={this.state.loading} />
                        <div className="content-wrapper">
                            <section className="content-header">
                                <div className="container-fluid">
                                    <div className="row mb-2">
                                        <div className="col-sm-6">
                                            <h1>Blocked Video List</h1>
                                        </div>
                                        <div className="col-sm-6">
                                            <ol className="breadcrumb float-sm-right">
                                                <li className="breadcrumb-item"><a href={'/kurafatadmin'}>Home</a></li>
                                                <li className="breadcrumb-item active">Blocked Videos</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section className="content">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header">

                                                </div>
                                                <div className="card-body">
                                                    <DataTable
                                                        title="Video"
                                                        columns={columns}
                                                        data={this.state.posts.data}
                                                        highlightOnHover
                                                        pagination
                                                        paginationServer
                                                        paginationTotalRows={this.state.posts.total}
                                                        paginationPerPage={this.state.countPerPage}
                                                        paginationComponentOptions={{
                                                            noRowsPerPage: true
                                                        }}
                                                        onChangePage={page => this.setPage(page)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        );
    }
}

export default BlockedVideos;