import React from 'react';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.js";
import './Assets/css/jquery.dataTables.css';
import './Assets/css/all.min.css';
import './Assets/css/custom.css';
import './Assets/css/style.css';

import Routes from "./Commen/Routes";
import withClearCache from "./ClearCache";
import { messaging } from "./init-fcm";

const ClearCacheComponent = withClearCache(MainApp);

const App =() =>{
  return <ClearCacheComponent />;

};

function MainApp(props) {
    if (messaging) {
        messaging.requestPermission()
            .then(async function() {
                const token = await messaging.getToken();
                localStorage.setItem('fcm-token', token);
            })
            .catch(function(err) {
                console.log("Unable to get permission to notify.", err);
            });
        navigator.serviceWorker.addEventListener("message", (message) => console.log(message));

        messaging.onMessage((payload) => console.log('Message received. ', payload));
    }

  return(
      <Routes/>
  );
}

export default App;