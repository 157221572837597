import React from 'react';
import $ from "jquery";
import {callAPI, userData} from "../../Commen/CommenFunction";
import CircularProgress from '@material-ui/core/CircularProgress';
import SendTwoToneIcon from '@material-ui/icons/SendTwoTone';
import {Link} from "react-router-dom";
import videoIcon from "../../Assets/img/video-icon.svg";
import {NotificationsNone} from "@material-ui/icons";
import swal from "sweetalert";


class CommentSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            post_id:null,
            page:1,
            comments:[],
            paginate:false,
            commentLoading:false,
            post:[],
            message:"",
            comment_count:0,
            last_page:1
        }
    }

    async componentDidMount() {
        let scrollDiv = $('.comment-message-sec');

        scrollDiv.on('scroll',() => {
            if(scrollDiv.scrollTop() + scrollDiv.innerHeight() >= scrollDiv[0].scrollHeight) {
                if (this.state.page <= this.state.last_page){
                    if (!this.state.commentLoading){
                        this.setState({
                            commentLoading:true,
                        });
                        this.getCommentList(this.props.post.id, this.state.page);
                    }
                }
            }
        });
    }
    removeCommentPopup(){
        $('.comment-opt-slide').removeClass('active');
        $('.backdrop').remove();
    }

    async getCommentList(post_id, page){
        if (!post_id){
            return
        }
        if (page == 1){
            this.setState({
                comments: [],
            });
        }
        const formVideoData = new FormData()
        formVideoData.set('page', page)
        formVideoData.set('post_id', post_id)
        try {
            let response = await callAPI('getCommentList', 'post', formVideoData, 'application/json');
            if (response.status) {
                if (response.status_code == 1){
                    this.setState({
                        found: false,
                        paginate: false,
                        comments: [],
                        commentLoading:false
                    });
                } else {
                    response.data.data.forEach(el => {
                        this.state.comments.push(el)
                    });

                    this.setState({
                        comments: this.state.comments,
                        paginate: false,
                        commentLoading:false,
                        page:this.state.page+1,
                        last_page:response.data.last_page
                    });
                }
            } else {
                this.setState({
                    found: false,
                    paginate: false,
                    commentLoading:false
                });
            }
        } catch (e) {
            console.log(e);
        }
    }
    async inputChangeHandler(event, key) {
        const value = event.target.value;

        this.setState(state => {
            return {
                ...state,
                [key]: value
            }
        })
    }

    async submitHandler(event) {
        event.preventDefault();

        if (!userData){
            $('.login-opt-slide').addClass('active');
            return;
        }
        const form = new FormData()
        form.set('comment', this.state.message)
        form.set('user_id', userData.id)
        form.set('post_id', this.props.post.id)

        this.setState({
            commentLoading:true
        });
        try {
            const response = await callAPI('createComment', 'post', form,'application/json');
            if (response.status === true) {
                this.state.comments.unshift(response.data)
                $('.comment-message-sec').animate({ scrollTop: 0 }, "slow");
                this.setState({
                    comments: this.state.comments,
                    paginate: false,
                    commentLoading:false,
                    message:'',
                    comment_count:this.state.comment_count+1
                });
            } else {
                swal(response.message)
                this.setState({
                    paginate: false,
                    commentLoading:false,
                    message:''
                });
            }

            this.setState({
                loading:false
            });
        } catch (e) {
            console.log(e);
        }
    }

  render() {
      if (this.props.post && this.state.post_id != this.props.post.id){
          if (!this.state.commentLoading){
              if (this.props.post.comment_count){
                  this.setState({
                      post_id: this.props.post.id,
                      commentLoading:true,
                      post:this.props.post,
                      comment_count:this.props.post.comment_count,
                      page:1
                  });
                  this.getCommentList(this.props.post.id, 1);
              } else {
                  this.setState({
                      post_id: this.props.post.id,
                      post:this.props.post,
                      comment_count:this.props.post.comment_count,
                      comments: [],
                      page:1
                  });
              }
          }
      }
    return(
        <>
          <div className="comment-opt-slide">
            <div className="cls-btn" onClick={()=>this.removeCommentPopup()}><i className="fas fa-times"></i></div>
              <div className="comment-row">
                  <div className="post-user-img"><img src={this.props.post.user.image_url}
                                                className="rounded-circle img-fluid"/></div>
                  <div className="cat-info">
                      <div className="user-name-id"><h3>{this.props.post.user.name}</h3>
                          <div>{this.state.comment_count} Comment</div>
                      </div>
                      <a href="#" className="followbtn">Follow</a></div>
              </div>

              <div className="comment-message-sec">
                  {
                      this.state.comments.map((comment, key )=> {
                          return (
                              <div className="comment-message" key={key}>
                                  <div className="comment-user-img"><img src={comment.user.image_url}
                                                         className="rounded-circle img-fluid"/></div>
                                      <div className="comment-user"><h3>{comment.user.name}</h3>
                                          <div className="message">{comment.comment}</div>
                                      </div>
                              </div>
                          )
                      })}
              </div>
              <div className="comment-chat-area">
                  <form  method="post" id="registrationForm" onSubmit={(event) => this.submitHandler(event)}>
                      <input type="text" value={this.state.message}
                             onChange={(event) => this.inputChangeHandler(event, 'message')}
                             placeholder="Message Here"
                             className="comment-input"/>
                      <button><SendTwoToneIcon fontSize="large" /></button>
                  </form>
              </div>
              {this.state.commentLoading && <CircularProgress /> }
          </div>
        </>
    )
  }
}


export default CommentSection;