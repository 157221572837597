import React from 'react';
import swal from "sweetalert";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import {callAdminAPI} from "../../../Commen/CommenFunction";
import dummyImg from '../../../Assets/img/dummy_user.png';
import Sidebar from "../Layout/Sidebar";
import DataTable from "react-data-table-component";


class Users extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            users:[],
            column:null,
            verified:1,
            direction:null
        }
    }

   async componentDidMount() {
        await this.getUsers(1);
    }

    async getUsers(page, column=null, direction=null, search=null, verified=null) {
        try {
            this.setState({
                loading:true
            });

            if (verified == null) {
                verified = this.state.verified
            }

            const form = new FormData()
            form.set('page', page)
            form.set('column', column)
            form.set('direction', direction)
            form.set('search', search)
            form.set('verified', verified)
            const response = await callAdminAPI('admin/userList', 'post',form,'application/json');

            if (response.data.status === true) {
                if (response.data.status_code == 0){
                    this.setState({
                        users:response.data.data
                    });
                }
            }
            this.setState({
                loading:false
            });
        } catch (e) {
            console.log(e);
        }
    }

    async setPage(page){
        await this.getUsers(page, this.state.column, this.state.direction, this.state.search)
    }

    async setSortPage(column, sortDirection, event){
        this.setState({
            column:column.selector,
            direction:sortDirection
        });
        await this.getUsers(1, column.selector, sortDirection, this.state.search)
    }

    async changeVerifyStatus(id) {
        swal({
            title: "Are you sure?",
            text: "Do you want to change the verify status!",
            icon: "warning",
            buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
            ],
            dangerMode: true,
        }).then(async function(isConfirm) {
            if (isConfirm) {
                try {
                    const form = new FormData()
                    form.set('user_id', id)
                    const response = await callAdminAPI('admin/changeVerifyStatus', 'post', form, 'application/json');
                    if (response.data.status === true) {
                        window.location.reload();
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        })
    }
    async filterData(event){
        let val = event.target.value;
        this.setState({
            search:val
        });
        await this.getUsers(1, this.state.column, this.state.direction, val)
    }

    async inputChangeHandler(event, key) {
        let value = event.target.value;
        this.setState(state => {
            return {
                ...state,
                [key]: value
            }
        })
        await this.getUsers(1, this.state.column, this.state.direction, this.state.search, value)
    }

    render() {
        const columns = [
            {
                name: 'Name',
                selector: 'name',
                sortable: true
            },

            {
                name: 'Image',
                cell: row => <img height={50} width={50} src={row.image_url ? row.image_url : dummyImg } />
            },
            {
                name: 'Mobile',
                selector: 'mobile',
                sortable: true
            },
            {
                name: 'Status',
                cell: row => row.name ? 'Verified' : 'Unverified'
            },
            {
                name: 'Location',
                selector: 'ip_address',
            },
            {
                name: 'Referred By',
                cell: row => row.referred_by ? <a href={'user_detail/'+row.referred_by.id} className='btn btn-primary'>{row.referred_by.name}</a> : 'Self'
            },
            {
                name: 'K Verified',
                cell: row => row.k_verified ? <button onClick={()=>this.changeVerifyStatus(row.id)} className='btn btn-primary'>Verified</button> : <button onClick={()=>this.changeVerifyStatus(row.id)} className='btn btn-danger'>Un-Verified</button>
            },
            {
                name: 'Refer Count',
                selector: 'refer_count',
                sortable: true
            },
            {
                name: 'Follower Count',
                selector: 'follower_count',
                sortable: true
            },
            {
                name: 'Following Count',
                selector: 'following_count',
                sortable: true
            },
            {
                name: 'Like Count',
                selector: 'like_count'
            },
            {
                name: 'Post Count',
                selector: 'post_count',
                sortable: true
            },
            {
                name: 'Register By',
                selector: 'register_by',
            },
            {
                name: 'Joined date',
                selector: 'user_created_date_time',
                sortable: true
            },
            {
                name: 'Action',
                cell: row => <a href={'user_detail/'+row.id} className='btn btn-primary'><i className='fa fa-eye'></i></a>
            },
        ];
        return(
            <div id="wrapper">
                <Sidebar/>
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Header loading={this.state.loading} />
                        <div className="content-wrapper">
                            <section className="content-header">
                                <div className="container-fluid">
                                    <div className="row mb-2">
                                        <div className="col-sm-6">
                                            <h1>User List</h1>
                                        </div>
                                        <div className="col-sm-6">
                                            <ol className="breadcrumb float-sm-right">
                                                <li className="breadcrumb-item"><a href={'/kurafatadmin'}>Home</a></li>
                                                <li className="breadcrumb-item active">Users</li>
                                            </ol>
                                        </div>
                                    </div>
                                    <div className="user-filter">
                                        <select name="verified" onChange={(event) => this.inputChangeHandler(event, 'verified')} className="form-control" >
                                            <option value="">--Option--</option>
                                            <option selected={this.state.verified == 0} value="0">Unverified</option>
                                            <option selected={this.state.verified == 1} value="1">Verified</option>
                                            <option selected={this.state.verified == 2} value="2">Both</option>
                                        </select>

                                        <input type="search" className="form-control"
                                               onChange={(event) => this.filterData(event)}
                                               placeholder="Search"/>
                                    </div>
                                </div>
                            </section>

                            <section className="content">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header">
                                                </div>
                                                <div className="card-body">
                                                    <DataTable
                                                        title="User List"
                                                        columns={columns}
                                                        data={this.state.users.data}
                                                        highlightOnHover
                                                        pagination
                                                        paginationServer
                                                        paginationTotalRows={this.state.users.total}
                                                        paginationPerPage={this.state.countPerPage}
                                                        paginationComponentOptions={{
                                                            noRowsPerPage: true
                                                        }}
                                                        onChangePage={page => this.setPage(page)}
                                                        onSort={(column, sortDirection, event) => this.setSortPage(column, sortDirection, event)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        );
    }
}

export default Users;