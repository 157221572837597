import React, {PureComponent} from 'react';
import '../video/VideoPlayer.css';
import Video from '../video/video/Video';
import {callAPI} from "../../Commen/CommenFunction";
import $ from "jquery";
import Loading from "../Layout/Loading";
import ReactPageScroller from 'react-page-scroller';


class PlayVideoSection extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            videos:[],
            count:0,
            loading:true,
            paginate:true,
            page:1,
            callApiCount:14,
            index: 0,
            currentPage: 0,
            customPage:null,
        }
        this.fetchVideo = this.fetchVideo.bind(this);
    }

    async componentDidMount() {
        console.log(this.props.history.location.state);
        if(this.props.history.location.state && this.props.history.location.state.videos){
            this.props.history.location.state.videos.unshift(this.props.history.location.state.currentVideo);
            this.setState({
                videos: this.props.history.location.state.videos,
                loading: false,
                paginate: false,
                /*currentPage:this.props.history.location.state.index,
                customPage:this.props.history.location.state.index,*/
                page:this.props.history.location.state.page,
                user_id: this.props.history.location.state.user_id ? this.props.history.location.state.user_id : '',
                sound_id: this.props.history.location.state.sound_id ? this.props.history.location.state.sound_id : '',
                hashtag: this.props.history.location.state.hashtag ? this.props.history.location.state.hashtag : '',
                video_type:this.props.history.location.state.video_type
            });
        }
        $(".video_section").bind("mousewheel", function() {
            return false;
        });
        /*document.addEventListener('contextmenu', (e) => {
            e.preventDefault();
        });*/
    }

    nav = React.createRef();


    async fetchVideo(){
        const formVideoData = new FormData()
        formVideoData.set('page', this.state.page)
        formVideoData.set('video_type', this.state.video_type)
        formVideoData.set('user_id', this.state.user_id)
        formVideoData.set('sound_id', this.state.sound_id)
        formVideoData.set('hashtag', this.state.hashtag)
        try {
            let response = await callAPI('videoScreen', 'post', formVideoData, 'application/json');
            if (response.status) {
                response.data.data.forEach(el => {
                    this.state.videos.push(el)
                });
                this.setState({
                    videos: this.state.videos,
                    loading: false,
                    paginate: false
                });
            } else {
                this.setState({
                    loading: false,
                    found: false,
                    paginate: false
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    handlePageChange = number => {
        this.setState({ currentPage: number });
        for (let i = 0 ; i < $('video').length ; i++){
            $('video')[i].muted = false;
            $('.unmute-video').hide();
        }
    };

    handleBeforePageChange = async number => {
        if (number < this.state.customPage){
            this.setState({
                customPage: 0,
            });
        }
        if (number == this.state.callApiCount) {
            this.setState({
                paginate: true,
                callApiCount:this.state.callApiCount+18,
            });
            await this.fetchVideo();
            this.setState({
                page:this.state.page+1,
            });
        }
    };

    render() {
        if(this.state.loading) {
            return (
                <Loading />
            );
        } else {
            return (
                <div className="video_section">
                    <div className="back_arrow"><i onClick={this.props.history.goBack} className="fa fa-arrow-left" aria-hidden="true"></i></div>
                    <p className="unmute-video">Tap to Play</p>
                    <div className="app__videos" ref={this.nav}>
                        { this.state.videos.length > 1 ?
                            <ReactPageScroller
                                pageOnChange={this.handlePageChange}
                                onBeforePageScroll={this.handleBeforePageChange}
                                customPageNumber={this.state.currentPage}
                                animationTimer={500}
                                animationTimerBuffer={50}
                            >
                                {
                                    this.state.videos.map((video, key) => {
                                        return (
                                            <Video
                                                messages={video.comment_count}
                                                likes={video.like_count}
                                                shares={video.share_count}
                                                description={video.description}
                                                channel={video.user}
                                                song={video.get_sound}
                                                url={video.file_url}
                                                thumb={video.thumb_url}
                                                currentPage={this.state.customPage}
                                                liked={video.liked}
                                                dataId={key}
                                                key={key}
                                            />
                                        )
                                    })}
                            </ReactPageScroller> :

                            this.state.videos.map((video, key) => {
                                return (
                                    <Video
                                        messages={video.comment_count}
                                        likes={video.like_count}
                                        shares={video.share_count}
                                        description={video.description}
                                        channel={video.user}
                                        song={video.get_sound}
                                        url={video.file_url}
                                        thumb={video.thumb_url}
                                        currentPage={this.state.customPage}
                                        liked={video.liked}
                                        dataId={key}
                                        key={key}
                                    />
                                )
                            })
                        }

                    </div>
                </div>
            );
        }
    }
}

export default PlayVideoSection;
