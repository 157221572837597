import React from 'react';
import '../../Assets/css/mobile_web.css';
import {callAPI, userData} from "../../Commen/CommenFunction";
import MetaTags from 'react-meta-tags';
import dummyImg from '../../Assets/img/dummy_user.png';
import videoIcon from '../../Assets/img/video-icon.svg';
import $ from "jquery";
import {Link} from "react-router-dom";
import LoginPopup from "./LoginPopup";
import { withRouter } from "react-router-dom";
import Swipe from "react-easy-swipe";


class webUserProfile extends React.Component{

    constructor(props) {
        super(props);
        this.state={
            loading:true,
            user:[],
            post:[],
            page:1,
            scrollHeight:300,
            found:false,
            next_page:true,
            video_url:[]
        };
    }

    async componentDidMount() {
        this.setState({
            user:this.props.user
        });

        $('body').addClass('theme_bg');

        await this.getPublishedPost();

        window.addEventListener('scroll', this.handleScroll, true);
    }

    handleScroll = async () => {
        let header = $('.cat-info-row');
        if (window.pageYOffset > 80) {
            header.addClass("sticky");
        } else {
            header.removeClass("sticky");
        }
        let scrollDiv = $('.u-gal-scroll');

        scrollDiv.on('scroll',async () => {
            if(scrollDiv.scrollTop() + scrollDiv.innerHeight() >= scrollDiv[0].scrollHeight  && this.state.next_page && this.state.paginate) {
                this.setState({
                    paginate:false,
                });
                await this.getPublishedPost();
            }
        });
    }

    async getPublishedPost() {
        const formData = new FormData()
        formData.set('user_id', this.props.user.id)
        formData.set('page', this.state.page)
        try {
            let response = await callAPI('userPublishedPost', 'post', formData, 'application/json');
            if (response.status){
                response.data.data.forEach(el => {
                    this.state.post.push(el)
                });
                let next_page = false
                if (response.data.next_page_url){
                    next_page = true;
                }

                this.setState({
                    post:this.state.post,
                    loading:false,
                    found:true,
                    page:this.state.page+1,
                    pageYOffset:this.state.pageYOffset+600,
                    next_page : next_page,
                    paginate:true
                });
            } else {
                this.setState({
                    loading:false,
                    found:false
                });
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    followUser(){
        if (!userData){
            $('.login-opt-slide').addClass('active');
        }
    }

    onSwipeMove = (position, event) => {
        if (position.x < -80){
            console.log('left');

        }

        if (position.x > 80){
            this.backToPage();
        }
    }

    backToPage() {
        if (this.props.location.state && this.props.location.state.video) {
           
            this.props.history.push({
                pathname: '/',
                state: {video:this.props.location.state.video }
            });
        } else {
            this.props.history.goBack();
        }
    }

    render() {
        if (this.props.location.state && this.props.location.state.video){
            if(this.state.video_url.length == 0){
                this.setState({
                    video_url:this.props.location.state.video
                });
            }
        }

        return (
            <div>
                <MetaTags>
                    <title>{this.state.user.name+" (@"+this.state.user.username+") . kurafat Posts"}</title>
                    <meta name="description" content={this.state.user.follower_count+" Followers, "+this.state.user.following_count+" Following, "+this.state.user.post_count+" Posts - See kurafat Posts from "+this.state.user.name+" (@"+this.state.user.username+")"} />
                    <meta property="og:title" content={this.state.user.name+" (@"+this.state.user.username+") . kurafat Posts"} />
                    <meta property="og:image" content={this.state.user.image_url} />
                </MetaTags>
                <Swipe
                    onSwipeMove={this.onSwipeMove}>
                    <div className="mb-container  vh-100 pag-tp-pt">
                        <div className="back_arrow"><a href='#' onClick={()=>this.backToPage()}><i className="fa fa-arrow-left" aria-hidden="true"></i></a></div>
                        <div className="user-profile-video-section">
                            <div className="cat-info-row" id="pro-row-fix">
                                <div className="cat-img">
                                    <img src={this.state.user.image_url ? this.state.user.image_url : dummyImg} className="rounded-circle img-fluid" />
                                </div>
                                <div className="cat-info">
                                    <div className="user-name-id">
                                        <h3>{this.state.user.name}</h3>
                                        <div className="userid">ID: {this.state.user.username}</div>
                                    </div>
                                    <a href="#" className="followbtn" onClick={()=>this.followUser()}>Follow</a>
                                </div>
                            </div>
                            <ul className="user-flower-list">
                                <li>
                                    <span className="fl-num">{this.state.user.following_count}</span>
                                    <p>Following</p>
                                </li>
                                <li>
                                    <span className="fl-num">{this.state.user.follower_count}</span>
                                    <p>Followers</p>
                                </li>
                                <li>
                                    <span className="fl-num">{this.state.user.like_count}</span>
                                    <p>Likes</p>
                                </li>
                            </ul>
                            <ul className="user-vd-list">
                            {
                                this.state.post.map((post, key )=> {
                                    return (
                                        <li className="vd-list-item" key={key}>
                                            <div className="vd-like-count">Like: {post.like_count}</div>
                                            <Link  to={{
                                                pathname: '/videos',
                                                state: {
                                                    videos:this.state.post,
                                                    index:key,
                                                    page:this.state.page,
                                                    user_id_id:this.state.user.id,
                                                    video_type: 'published',
                                                    currentVideo:post
                                                }
                                            }}>
                                                <div className="vd-sign">
                                                    <img src={videoIcon} alt="" className="img-fluid" />
                                                </div>
                                            </Link>
                                            <img src={post.thumb_url} className="img-fluid" alt=""/>
                                        </li>
                                    )
                                })}
                        </ul>
                        </div>
                    </div>
                    <LoginPopup />
                </Swipe>
            </div>
        );
 }
}

export default withRouter(webUserProfile);