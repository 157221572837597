import React from 'react';
import '../../Assets/css/sb-admin-2.min.css';
import {callAdminAPI} from "../../Commen/CommenFunction";
import swal from "sweetalert";

class Home extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            email:'',
            password:'',
            user:[]
        }
    }

    async inputChangeHandler(event, key) {
        const value = event.target.value;

        this.setState(state => {
            return {
                ...state,
                [key]: value
            }
        })
    }

    async submitHandler(event) {
        event.preventDefault();

        const form = new FormData()
        form.set('email', this.state.email)
        form.set('password', this.state.password)
        this.setState({
            loading:true
        });
        try {
            const response = await callAdminAPI('admin/login', 'post', form,'application/json');

            if (response.data.status === true) {
                if (response.data.status_code == 1){
                    window.location.reload();
                } else {
                    localStorage.setItem("user", JSON.stringify(response.data.data));
                    window.location.reload();
                }
            } else {
                swal(response.data.message)
            }

            this.setState({
                loading:false
            });
        } catch (e) {
            console.log(e);
        }
    }

    render() {

        return(
            <>
                <div className="">
                    {(() => {
                        if (this.state.loading) {
                            return (
                                <div className="circle_loader_sec processing-loader">
                                    <div className="loader">
                                        <div className="m-t-30">
                                            <img src={require("../../Assets/img/circle-loader.gif")} />
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    })()}
                    <div className="container">

                        <div className="row justify-content-center">

                            <div className="col-xl-10 col-lg-12 col-md-9">

                                <div className="card o-hidden border-0 shadow-lg my-5">
                                    <div className="card-body p-0">
                                        <div className="row">
                                            <div className="col-lg-6 d-none d-lg-block"></div>
                                            <div className="col-lg-6">
                                                <div className="p-5">
                                                    <div className="text-center">
                                                        <h1 className="h4 text-gray-900 mb-4">Kurafat!</h1>
                                                    </div>
                                                    <form className="user" method="post" id="registrationForm" onSubmit={(event) => this.submitHandler(event)}>
                                                        <div className="form-group">
                                                            <input type="email" className="form-control form-control-user" value={this.state.email}
                                                                   onChange={(event) => this.inputChangeHandler(event, 'email')}
                                                                   name="email" id="email"
                                                                   placeholder="Email" required="required" />
                                                        </div>
                                                        <div className="form-group">
                                                            <input type="password" className="form-control form-control-user" value={this.state.password}
                                                                   onChange={(event) => this.inputChangeHandler(event, 'password')}
                                                                   name="password" id="password"
                                                                   placeholder="Password" required="required" />
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="custom-control custom-checkbox small">
                                                                <input type="checkbox" className="custom-control-input"
                                                                       id="customCheck" />
                                                                    <label className="custom-control-label"
                                                                           htmlFor="customCheck">Remember
                                                                        Me</label>
                                                            </div>
                                                        </div>
                                                        <button type="submit" className="btn btn-primary btn-user btn-block">Sign In</button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </>
        );
    }
}

export default Home;