import React from 'react';
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import {callAdminAPI} from "../../../Commen/CommenFunction";
import dummyImg from '../../../Assets/img/dummy_user.png';
import Sidebar from "../Layout/Sidebar";
import DataTable from 'react-data-table-component';
import swal from "sweetalert";
import $ from "jquery";
import googleBtn from "../../../Assets/img/googlebtn.png";
import imageCompression from "browser-image-compression";


class PromotionalImages extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            images:[],
            countPerPage:10,
            image_url:null,
            image:null,
        }
        this.handleChange = this.handleChange.bind(this)
    }

    async inputChangeHandler(event, key) {
        let value = event.target;
        if(event.target.type == 'file') {
            if(event.target.files['length'] < 1) {
                return false;
            }
            value = event.target.files[0];
        } else {
            value = event.target.value;
        }

        this.setState(state => {
            return {
                ...state,
                [key]: value
            }
        })
    }

   async componentDidMount() {
        await this.getImages(1)
    }

    async getImages(page) {
        try {
            this.setState({
                loading:true
            });
            const form = new FormData()
            form.set('page', page)

            const response = await callAdminAPI('admin/promotionalImage', 'post', form,'application/json');

            if (response.data.status === true) {
                if (response.data.status_code == 0){
                    this.setState({
                        images:response.data.data
                    });
                }
            }
            this.setState({
                loading:false
            });
        } catch (e) {
            console.log(e);
        }
    }

    async setPage(page){
        await this.getVideo(page)
    }

    async deleteImage(post_id){
        swal({
            title: "Are you sure?",
            text: "You will get this post in blocked list!",
            icon: "warning",
            buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
            ],
            dangerMode: true,
        }).then(async function(isConfirm) {
            if (isConfirm) {

                const form = new FormData()
                form.set('post_id', post_id)
                const response = await callAdminAPI('admin/changePostStatus', 'post', form,'application/json');

                if (response.data.status === true) {
                    if (response.data.status_code == 0){
                       window.location.reload();
                    }
                }
            } else {
                swal("Cancelled", "Your Image is safe :)", "error");
            }
        })
    }

    async handleChange(event) {
        if(event.target.files['length'] < 1) {
            return false;
        }
        var imageFile = event.target.files[0];

        var mimeType=imageFile['type'];
        if(mimeType.split('/')[0] === 'image'){
            var options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            }
            const compressedFile = await imageCompression(imageFile, options);
            this.setState({
                image_url: URL.createObjectURL(compressedFile),
                image:compressedFile
            })
        } else {
            this.setState({
                image:imageFile
            })
        }

    }

    async submitHandler(event) {
        this.setState({
            loading:true
        });
        event.preventDefault();
        const form = new FormData()
        if (this.state.image) {
            form.append(
                "image",
                this.state.image,
                this.state.image.name
            );
        }

        try {
            const response = await callAdminAPI('admin/uploadImage', 'post', form,'application/json');

            this.setState({
                loading:false
            });
            if (response.data.status == true){
                swal(response.data.message);
                window.location.reload();
            } else {
                swal(response.data.message)
            }

        } catch (e) {
            this.setState({
                loading:false
            });
        }
    }

    render() {
        const columns = [
            {
                name: 'Thumb',
                cell: row => <img height={50} width={50} alt={row.image_url} src={row.image_url} />,
            },
            {
                name: 'URL',
                selector: 'image_url'
            },
        ];

        return(
            <div id="wrapper">
                <Sidebar/>
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Header loading={this.state.loading} />
                        <div className="content-wrapper">
                            <section className="content-header">
                                <div className="container-fluid">
                                    <div className="row mb-2">
                                        <div className="col-sm-6">
                                            <h1>Active Video List</h1>
                                        </div>
                                        <div className="col-sm-6">
                                            <ol className="breadcrumb float-sm-right">
                                                <li className="breadcrumb-item"><a href={'/kurafatadmin'}>Home</a></li>
                                                <li className="breadcrumb-item active">Promotional Image</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section className="content">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <button className="btn btn-success float-right" data-toggle="modal" data-target="#createPromotional"><i className="fa fa-plus"></i> Create </button>
                                                </div>
                                                <div className="card-body">
                                                    <DataTable
                                                        title="Promotional Image"
                                                        columns={columns}
                                                        data={this.state.images.data}
                                                        highlightOnHover
                                                        pagination
                                                        paginationServer
                                                        paginationTotalRows={this.state.images.total}
                                                        paginationPerPage={this.state.countPerPage}
                                                        paginationComponentOptions={{
                                                            noRowsPerPage: true
                                                        }}
                                                        onChangePage={page => this.setPage(page)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                    <Footer/>
                </div>
                <div className="modal fade modal-radius" id="createPromotional" tabIndex="-1" role="dialog"
                     aria-labelledby="downloadModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="container cont-mini">
                                    <div className="col-md-11 mx-auto">
                                        <form className="form" method="post" id="registrationForm" onSubmit={(event) => this.submitHandler(event)}>
                                            <div className="card-body">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-8">
                                                            <label htmlFor="exampleInputFile">File input</label>
                                                            <div className="input-group">
                                                                <div className="custom-file">
                                                                    <input type="file" className="custom-file-input"
                                                                           id="exampleInputFile" accept=".png, .jpg, .jpeg, .mp4,.webm,.hdv,.flv,.avi,.wmv,.mov" onChange={this.handleChange} />
                                                                    <label className="custom-file-label"
                                                                           htmlFor="exampleInputFile">Choose
                                                                        file</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            {this.state.image_url ? <img style={{height:'100%', width:'100%'}} src={this.state.image_url} alt="event" className="pro-img"/> : '' }
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="card-footer">
                                                <button type="submit" className="btn btn-primary">Create</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PromotionalImages;