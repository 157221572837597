import React from 'react';
import '../../Assets/css/button.scss';
import '../../Assets/css/Home.scss';
import VideoPlayer from "../video/VideoPlayer";
import Website from "./Website";

class Home extends React.Component{

    render() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
          // true for mobile device
          return(
              <VideoPlayer/>
          )
      }else{
          // false for not mobile device
          return(
              <Website />
          );
      }
 }
}

export default Home;